export const SIGNAL_RECEIVE_MSG = 'SIGNAL_RECEIVE_MSG'
export const SIGNAL_CLEAN_COUNT = 'SIGNAL_CLEAN_COUNT'

export const broadcastMsg = (action) => {
  return (dispatch) => {
    dispatch({ type: SIGNAL_RECEIVE_MSG, message: action })
  }
}

export const cleanupCount = () => {
  return (dispatch) => dispatch({ type: SIGNAL_CLEAN_COUNT })
}
