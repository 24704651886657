// Fake DB
import './@fake-db'
import './configs/i18n'
// ** Ripple Button
import './@core/components/ripple-button'
// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'
// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'
// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'
// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import 'axios-progress-bar/dist/nprogress.css'

// ** Sentry Import
import * as Sentry from '@sentry/browser'
// ** Service Worker
import * as serviceWorker from './serviceWorker'

// ** React Imports
import { Suspense, lazy } from 'react'

import { AbilityContext } from './utility/context/Can'
// ** Auth Import
import { Auth0Provider } from './authServices/auth0/auth0Service'
import { Integrations } from '@sentry/tracing'
import { IntlProviderWrapper } from './utility/context/Internationalization'
import PostModal from './views/pages/social/crawled/PostModal'
// ** Redux Imports
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'
import { ThemeContext } from './utility/context/ThemeColors'
import { ToastContainer } from 'react-toastify'
// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability'
import config from './authServices/auth0/auth0Config.json'
import { loadProgressBar } from 'axios-progress-bar'
import { store } from './redux/storeConfig/store'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

// Sentry.init({
//   dsn: 'https://6ba85106fd6b4eba91a166c671361381@o465147.ingest.sentry.io/5477337',
//   integrations: [new Integrations.BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0
// })

loadProgressBar()
ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH_DOMAIN}
    client_id={process.env.REACT_APP_AUTH_CLIENTID}
    audience={process.env.REACT_APP_AUTH_AUDIENCE}
    cacheLocation="localstorage"
    redirect_uri={window.location.origin + process.env.REACT_APP_PUBLIC_PATH}
    responseType="token id_token"
    scope="openid email profile user_metadata"
  >
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <IntlProviderWrapper>
              <PostModal />
              <LazyApp />
              {/* <ToastContainer newestOnTop /> */}
            </IntlProviderWrapper>
          </ThemeContext>
        </AbilityContext.Provider>
      </Suspense>
    </Provider>
  </Auth0Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
