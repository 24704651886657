import {
  CHECKED_TAG,
  CLEAR_FILTERS,
  CLEAR_TAGS,
  DONE_DATA_FETCH,
  DONE_DATA_PARTIAL_FETCH,
  FILTER_TAG,
  INIT_GRIDCOLUMN_API,
  INIT_GRID_API,
  ROW_CHECKED,
  SET_ACCOUNTS,
  SET_CNT_RESULTS,
  SET_DATA,
  SET_MAX_ENG_INLIST,
  SET_MAX_FOL_INLIST,
  SET_NETWORKS,
  SET_TAGS,
  SHOW_LOADING_OVERLAY,
  SIDE_ER_SET_MAX,
  SIDE_ER_SET_MIN,
  SIDE_FOL_SET_MAX,
  SIDE_FOL_SET_MIN,
  SIDE_GIFTED_SINCE_3M_CHECKED,
  SIDE_GIFTED_SINCE_6M_CHECKED,
  SIDE_GIFTED_SINCE_ALL_CHECKED,
  SIDE_GIFTED_SINCE_CUSTOM_CHECKED,
  SIDE_REM_ALL_CHECKED,
  SIDE_REM_FREE_CHECKED,
  SIDE_REM_GIFT_CHECKED,
  SIDE_REM_MONEY_CHECKED,
  SIDE_SINCE_3M_CHECKED,
  SIDE_SINCE_6M_CHECKED,
  SIDE_SINCE_ALL_CHECKED,
  SIDE_SINCE_CUSTOM_CHECKED,
  SIDE_STAT_ALL_CHECKED,
  SIDE_STAT_BAN_CHECKED,
  SIDE_STAT_INRELATION_CHECKED,
  SIDE_STAT_NOREPLY_CHECKED,
  SIDE_STAT_NOTREACHED_CHECKED,
  SIDE_STAT_RELENGAGED_CHECKED,
  START_FETCH_DATA,
  START_FETCH_PARTIAL_DATA,
  UNCHECKED_NETWORKS
} from '../../actions/userGrid'

import { filter } from 'dom7'

const initialState = {
  accounts: [],
  globalLoading: false,
  partialLoaded: false,
  gridApi: null,
  gridColumnApi: null,
  dataLoaded: false,
  rowData: null,
  filteredData: [],
  checkedTags: [],
  resultsFound: 0,
  rowsChecked: 0,
  sideBarOptions: {
    networks: [],
    excludedNetworks: [],
    remuneration: {
      all: true,
      gifts: false,
      money: false,
      free: false
    },
    status: {
      all: true,
      relEngaged: false,
      notReached: false,
      inRelation: false,
      noReply: false,
      ban: false
    },
    contactedSince: {
      all: true,
      threeM: false,
      sixM: false,
      custom: false
    },
    giftedSince: {
      all: true,
      threeM: false,
      sixM: false,
      custom: false
    },
    sliderFollowers: {
      min: 1,
      max: 1,
      everMax: 1
    },
    sliderEngagement: {
      min: 0,
      max: 0,
      everMax: 0
    },
    tags: [],
    counts: {}
  },
  toJSON: () => {
    return { ...initialState, rowData: [] }
  }
}

const userGridReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_GRID_API:
      return { ...state, gridApi: action.value }
    case INIT_GRIDCOLUMN_API:
      return { ...state, gridColumnApi: action.value }
    case START_FETCH_DATA:
      return { ...state, dataLoaded: false }
    case START_FETCH_PARTIAL_DATA:
      return { ...state, partialLoaded: false }
    case DONE_DATA_FETCH:
      return { ...state, dataLoaded: true }
    case DONE_DATA_PARTIAL_FETCH:
      return { ...state, partialLoaded: true }
    case SET_DATA:
      return { ...state, rowData: action.value }
    case SET_CNT_RESULTS:
      return { ...state, resultsFound: action.value }
    case ROW_CHECKED:
      return { ...state, rowsChecked: action.value }
    case SIDE_SINCE_ALL_CHECKED:
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          contactedSince: {
            all: true,
            threeM: false,
            sixM: false,
            custom: false
          }
        }
      }
    case SIDE_SINCE_3M_CHECKED:
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          contactedSince: {
            all: false,
            threeM: true,
            sixM: false,
            custom: false
          }
        }
      }
    case SIDE_SINCE_6M_CHECKED:
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          contactedSince: {
            all: false,
            threeM: false,
            sixM: true,
            custom: false
          }
        }
      }
    case SIDE_SINCE_CUSTOM_CHECKED:
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          contactedSince: {
            all: false,
            threeM: false,
            sixM: false,
            custom: true
          }
        }
      }

    case SIDE_GIFTED_SINCE_ALL_CHECKED:
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          giftedSince: {
            all: true,
            threeM: false,
            sixM: false,
            custom: false
          }
        }
      }
    case SIDE_GIFTED_SINCE_3M_CHECKED:
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          giftedSince: {
            all: false,
            threeM: true,
            sixM: false,
            custom: false
          }
        }
      }
    case SIDE_GIFTED_SINCE_6M_CHECKED:
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          giftedSince: {
            all: false,
            threeM: false,
            sixM: true,
            custom: false
          }
        }
      }
    case SIDE_GIFTED_SINCE_CUSTOM_CHECKED:
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          giftedSince: {
            all: false,
            threeM: false,
            sixM: false,
            custom: true
          }
        }
      }

    case SIDE_FOL_SET_MIN: {
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          sliderFollowers: {
            ...state.sideBarOptions.sliderFollowers,
            min: action.value
          }
        }
      }
    }
    case SIDE_FOL_SET_MAX: {
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          sliderFollowers: {
            ...state.sideBarOptions.sliderFollowers,
            max: action.value
          }
        }
      }
    }
    case SET_MAX_FOL_INLIST:
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          sliderFollowers: {
            ...state.sideBarOptions.sliderFollowers,
            everMax: action.value,
            max: action.value
          }
        }
      }
    case SET_MAX_ENG_INLIST:
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          sliderEngagement: {
            ...state.sideBarOptions.sliderEngagement,
            max: action.value,
            everMax: action.value
          }
        }
      }
    case SIDE_ER_SET_MIN: {
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          sliderEngagement: {
            ...state.sideBarOptions.sliderEngagement,
            min: action.value
          }
        }
      }
    }
    case SIDE_ER_SET_MAX: {
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          sliderEngagement: {
            ...state.sideBarOptions.sliderEngagement,
            max: action.value
          }
        }
      }
    }
    case SIDE_REM_ALL_CHECKED:
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          remuneration: {
            all: true,
            gift: false,
            money: false,
            free: false
          }
        }
      }
    case SIDE_REM_GIFT_CHECKED:
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          remuneration: {
            all: false,
            gift: true,
            money: false,
            free: false
          }
        }
      }
    case SIDE_REM_MONEY_CHECKED:
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          remuneration: {
            all: false,
            gift: false,
            money: true,
            free: false
          }
        }
      }
    case SIDE_REM_FREE_CHECKED:
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          remuneration: {
            all: false,
            gift: false,
            money: false,
            free: true
          }
        }
      }
    case SIDE_STAT_ALL_CHECKED:
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          status: {
            all: true,
            relEngaged: false,
            notReached: false,
            inRelation: false,
            noReply: false,
            ban: false
          }
        }
      }
    case SIDE_STAT_RELENGAGED_CHECKED:
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          status: {
            all: false,
            relEngaged: true,
            notReached: false,
            inRelation: false,
            noReply: false,
            ban: false
          }
        }
      }
    case SIDE_STAT_NOTREACHED_CHECKED:
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          status: {
            all: false,
            relEngaged: false,
            notReached: true,
            inRelation: false,
            noReply: false,
            ban: false
          }
        }
      }
    case SIDE_STAT_INRELATION_CHECKED:
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          status: {
            all: false,
            relEngaged: false,
            notReached: false,
            inRelation: true,
            noReply: false,
            ban: false
          }
        }
      }
    case SIDE_STAT_NOREPLY_CHECKED:
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          status: {
            all: false,
            relEngaged: false,
            notReached: false,
            inRelation: false,
            noReply: true,
            ban: false
          }
        }
      }
    case SIDE_STAT_BAN_CHECKED:
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          status: {
            all: false,
            relEngaged: false,
            notReached: false,
            inRelation: false,
            reached: false,
            noReply: false,
            ban: true
          }
        }
      }
    case SET_TAGS: {
      return { ...state, tags: action.value }
    }
    case FILTER_TAG: {
      return { ...state, filteredData: action.value }
    }
    case CHECKED_TAG: {
      return { ...state, checkedTags: action.value }
    }
    case CLEAR_TAGS: {
      return { ...state, checkedTags: [], filteredData: [] }
    }
    case CLEAR_FILTERS:
      return {
        ...state,
        sideBarOptions: initialState.sideBarOptions
      }
    case SET_NETWORKS:
      return {
        ...state,
        sideBarOptions: { ...state.sideBarOptions, networks: action.value }
      }
    case UNCHECKED_NETWORKS: {
      return {
        ...state,
        sideBarOptions: {
          ...state.sideBarOptions,
          excludedNetworks: action.value
        }
      }
    }
    case SHOW_LOADING_OVERLAY:
      return {}
    case SET_ACCOUNTS: {
      return { ...state, accounts: action.value }
    }
    default:
      return state
  }
}

export default userGridReducer
