import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import CountriesService from '../../../../../../services/api/countries'

export const loadCountries = createAsyncThunk(
  'areasStore/loadCountries',
  async (params, { getState }) => {
    const store = getState().auth

    const countries = store.countries?.length
      ? store.countries
      : await CountriesService.getAreasFull()

    return store.countries?.length ? store.countries : countries.data.countries
  }
)

export const setupCountries = createAsyncThunk(
  'areasStore/setupCountries',
  async (params, { getState }) => {
    const store = getState().areasStore
    const container = {}
    if (store.countries.length) {
      store.countries.forEach((c) => {
        if (container[c.area] === undefined) {
          container[c.area] = []
        }
        container[c.area].push(c)
      })
    }

    return container
  }
)

export const loadAreas = createAsyncThunk('areasStore/loadAreas', async () => {
  const countries = await CountriesService.getAreas()
  countries.data.reverse()
  countries.data.push('All')
  countries.data.reverse()

  return countries.data
})

export const switchArea = createAsyncThunk(
  'areasStore/switchArea',
  async (params, { getState }) => {
    const store = getState().areasStore
    const countries = Object.assign([], store.countries)
    const selectedCountry = Object.assign(
      {},
      countries.find((n) => n.name === params.country)
    )
    try {
      selectedCountry['area'] = params.area.toString()
    } catch (exception) {
      console.log(exception)
    }

    return countries.map((obj) =>
      obj.name === selectedCountry.name ? selectedCountry : obj
    )
  }
)

export const selectArea = createAsyncThunk(
  'areasStore/selectArea',
  async (action) => {
    return action
  }
)

export const saveAreas = createAsyncThunk(
  'areasStore/saveAreas',
  async (params, { getState }) => {
    const state = getState().areasStore
    await CountriesService.postAreas(state.countries)

    return true
  }
)

export const createArea = createAsyncThunk(
  'areasStore/createArea',
  async (params, { getState }) => {
    const state = getState().areasStore
    const areas = Object.assign([], state.areas)

    areas.push(params)

    return areas
  }
)

export const areasStore = createSlice({
  name: 'areasStore',
  initialState: {
    container: {},
    areas: [],
    countries: [],
    selectedArea: 'All',
    loadingCountries: true,
    loadingAreas: true
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadCountries.pending, (state, action) => {
        state.loadingCountries = true
      })
      .addCase(loadCountries.rejected, (state, action) => {})
      .addCase(loadCountries.fulfilled, (state, action) => {
        state.countries = action.payload
        state.loadingCountries = false
      })

      .addCase(loadAreas.pending, (state, action) => {
        state.loadingAreas = true
      })
      .addCase(loadAreas.rejected, (state, action) => {})
      .addCase(loadAreas.fulfilled, (state, action) => {
        state.areas = action.payload
        state.loadingAreas = false
      })
      .addCase(selectArea.fulfilled, (state, action) => {
        state.selectedArea = action.payload
      })
      .addCase(setupCountries.fulfilled, (state, action) => {
        state.container = action.payload
      })

      .addCase(switchArea.fulfilled, (state, action) => {
        state.countries = action.payload
      })

      .addCase(saveAreas.pending, (state) => {
        state.loadingAreas = true
        state.loadingCountries = true
      })
      .addCase(saveAreas.rejected, (state, action) => {})
      .addCase(saveAreas.fulfilled, (state) => {
        state.loadingAreas = false
        state.loadingCountries = false
      })
      .addCase(createArea.fulfilled, (state, action) => {
        state.areas = action.payload
      })
  }
})

export default areasStore.reducer
