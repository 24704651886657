import CountriesService from '../../../../../../services/api/countries'
import UsersService from '../../../../../../services/api/users'

export const SET_USERNAME = 'manualCreateInfluencer/SET_USERNAME'
export const SET_VALID_USER = 'manualCreateInfluencer/SET_VALID_USER'
export const POP_ALERT = 'manualCreateInfluencer/POP_ALERT'
export const SET_AVATAR = 'manualCreateInfluencer/SET_AVATAR'
export const SET_AVATAR_ERR = 'manualCreateInfluencer/SET_AVATAR_ERR'
export const DOWNLOADED_COUNTRIES =
  'manualCreateInfluencer/DOWNLOADED_COUNTRIES'
export const SET_AREA = 'manualCreateInfluencer/SET_AREA'
export const SET_CITY = 'manualCreateInfluencer/SET_CITY'
export const SET_COUNTRY = 'manualCreateInfluencer/SET_COUNTRY'
export const SET_NAME = 'manualCreateInfluencer/SET_NAME'
export const SET_NETWORK = 'manualCreateInfluencer/SET_NETWORK'
export const SET_MAIL = 'manualCreateInfluencer/SET_MAIL'
export const SET_PHONE = 'manualCreateInfluencer/SET_PHONE'
export const SET_EXTERNAL_URL = 'manualCreateInfluencer/SET_EXTERNAL_URL'
export const SET_FOLLOWERS = 'manualCreateInfluencer/SET_FOLLOWERS'
export const RESET = 'manualCreateInfluencer/RESET'

export const setUsername = (value) => (dispatch) => {
  dispatch({ type: SET_USERNAME, value })
}

export const setAvatar = (value) => (dispatch) => {
  dispatch({ type: SET_AVATAR_ERR, value: false })
  dispatch({ type: SET_AVATAR, value })
}

export const setAvatarError = (value) => (dispatch) => {
  dispatch({ type: SET_AVATAR_ERR, value })
}

export const validateUsername = () => (dispatch, _getState) => {
  const state = _getState().manualCreator
  UsersService.search(state.username).then((result) => {
    dispatch({
      type: SET_VALID_USER,
      value:
        result.data.length === 0 ||
        result.data.filter((r) => r.username === state.username)?.length === 0
    })
  })
}

export const getCountries = () => (dispatch, getState) => {
  const authStore = getState().auth
  const localPromise = new Promise((resolve, reject) =>
    resolve(authStore.countries?.countries)
  )
  const myPromise = authStore.countries?.countries?.length
    ? localPromise
    : CountriesService.get()

  myPromise.then((result) => {
    const results = authStore.countries?.countries?.length
      ? authStore.countries?.countries
      : result.data
    const countries = results.map((c) => {
      return { value: c.value, label: c.label }
    })
    dispatch({ type: DOWNLOADED_COUNTRIES, value: countries })
  })
}

export const setCountry = (value) => (dispatch) => {
  dispatch({ type: SET_COUNTRY, value })
}

export const setArea = (value) => (dispatch) => {
  dispatch({ type: SET_AREA, value })
}

export const setName = (value) => (dispatch) => {
  dispatch({ type: SET_NAME, value })
}

export const setMail = (value) => (dispatch) => {
  dispatch({ type: SET_MAIL, value })
}
export const setPhone = (value) => (dispatch) => {
  dispatch({ type: SET_PHONE, value })
}

export const setCity = (value) => (dispatch) => {
  dispatch({ type: SET_CITY, value })
}

export const setNetwork = (value) => (dispatch) => {
  dispatch({ type: SET_NETWORK, value })
}
export const setFollowers = (value) => (dispatch) => {
  dispatch({ type: SET_FOLLOWERS, value })
}
export const setExternalUrl = (value) => (dispatch) => {
  dispatch({ type: SET_EXTERNAL_URL, value })
}

export const save = () => (dispatch, _getState) => {
  const state = _getState().manualCreator
  const followers = parseInt(state.followers, 10)

  const saveObject = {
    fullName: state.fullName,
    username: state.username,
    contactPhoneNumber: state.phone,
    publicEmail: state.email,
    isManualInsert: true,
    country: state.country,
    area: state.area,
    externalUrl: state.externalUrl,
    tags: [state.network.toLowerCase()],
    followerCount: followers,
    profilePicUrl: state.avatarUrl,
    networks: [
      {
        usernameInNetwork: state.username,
        networkName: state.network.toLowerCase(),
        isSourceNetwork: true,
        followerCount: followers
      }
    ]
  }

  return UsersService.post(saveObject)
}
