/**
 * Axios Request Wrapper
 * ---------------------
 *
 * @author  Sheharyar Naseer (@sheharyarn)
 * @license MIT
 *
 */

import axios from 'axios'

/**
 * Create an Axios Client with defaults
 */

const client = axios.create({
  //baseURL: '/',
  //auth: { Authorization: 'Bearer ' + token },
  //headers: { Authorization: 'Bearer ' + token },
})

/**
 * Request Wrapper with default success/error actions
 */
const request = function (options) {
  const token = options.token || localStorage.getItem('token') || ''
  options.headers = {
    ...options.headers,
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  }

  const onSuccess = function (response) {
    return response
  }

  const onError = function (error) {
    console.error('Request Failed:', error.config)

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:', error.response.status)
      console.error('Data:', error.response.data)
      console.error('Headers:', error.response.headers)
      // Construct a structured error object
      const errorInfo = {
        status: error.response.status, // Include the HTTP status code
        message: error.response.data.message || 'An error occurred', // Generic message if specific one is unavailable
        data: error.response.data // Include the response data (optional, might contain useful error details)
      }

      return Promise.reject(errorInfo) // Reject with structured error
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message)
      const errorInfo = {
        message: error.message
      }
      return Promise.reject(errorInfo)
    }
  }

  return client(options).then(onSuccess).catch(onError)
}

export default request
