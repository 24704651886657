import {
  DOWNLOADED_COUNTRIES,
  RESET,
  SET_AREA,
  SET_AVATAR,
  SET_AVATAR_ERR,
  SET_CITY,
  SET_COUNTRY,
  SET_EXTERNAL_URL,
  SET_FOLLOWERS,
  SET_MAIL,
  SET_NAME,
  SET_NETWORK,
  SET_PHONE,
  SET_USERNAME,
  SET_VALID_USER
} from './actions'

import i18next from 'i18next'

const initialState = {
  username: '',
  usernameValid: false,
  popAlert: false,
  defaultAvatar: 'https://drake.azureedge.net/assets/woman-avatar.webp',
  avatarUrl: 'https://drake.azureedge.net/assets/woman-avatar.webp',
  avatarError: false,
  countries: {},
  fullName: '',
  country: '',
  countryOpt: {
    value: 'invalid',
    label: i18next.t(
      'addContact.wizard.userData.countryPlaceholder',
      'Please Select a Country'
    )
  },
  netOpt: {
    value: 'invalid',
    label: i18next.t(
      'addContact.wizard.network.socialNetworkSelectLabel',
      'Please Select a Network'
    )
  },
  network: '',
  phone: '',
  area: '',
  city: '',
  email: '',
  externalUrl: '',
  followers: 0
}

const manualCreatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERNAME: {
      return {
        ...state,
        username: action.value,
        usernameValid: false,
        popAlert: false
      }
    }
    case SET_VALID_USER: {
      return { ...state, usernameValid: action.value, popAlert: !action.value }
    }
    case SET_AVATAR: {
      return { ...state, avatarUrl: action.value }
    }
    case SET_AVATAR_ERR: {
      return { ...state, avatarError: action.value }
    }
    case DOWNLOADED_COUNTRIES: {
      return { ...state, countries: action.value }
    }
    case SET_NETWORK: {
      return { ...state, network: action.value.label, netOpt: action.value }
    }
    case SET_COUNTRY: {
      return { ...state, country: action.value.label, countryOpt: action.value }
    }
    case SET_AREA: {
      return { ...state, area: action.value }
    }
    case SET_NAME: {
      return { ...state, fullName: action.value }
    }
    case SET_PHONE: {
      return { ...state, phone: action.value }
    }
    case SET_MAIL: {
      return { ...state, email: action.value }
    }
    case SET_CITY: {
      return { ...state, city: action.value }
    }
    case SET_FOLLOWERS: {
      return { ...state, followers: action.value }
    }
    case SET_EXTERNAL_URL: {
      return { ...state, externalUrl: action.value }
    }
    case RESET: {
      return { ...initialState }
    }
    default:
      return { ...state }
  }
}

export default manualCreatorReducer
