import request from '../../shared/lib/request'
const rootApiUrl = process.env.REACT_APP_API_URL

function get() {
  return request({
    url: `${rootApiUrl}/products`,
    method: 'GET'
  })
}
function getGroupped() {
  return request({
    url: `${rootApiUrl}/products/groupped`,
    method: 'GET'
  })
}
function search(val) {
  return request({
    url: `${rootApiUrl}/products/${val}/search/`,
    method: 'GET'
  })
}

function toggleExclusion(id) {
  return request({
    url: `${rootApiUrl}/products/exclude/${id}`,
    method: 'POST'
  })
}

function getUrl() {
  return `${rootApiUrl}/products`
}

function deleteProduct(id) {
  return request({
    url: `${rootApiUrl}/products/delete/${id}`,
    method: 'DELETE'
  })
}

const ProductsService = {
  get,
  getGroupped,
  getUrl,
  search,
  toggleExclusion,
  deleteProduct
}

export default ProductsService
