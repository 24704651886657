import {
  ADD_CATALOG,
  LOADING,
  LOADING_DONE,
  SET_AI_FILTER,
  SET_AREA_FILTER,
  SET_BRAND_FILTER,
  SET_CATALOG,
  SET_COUNTRY_FILTER,
  SET_HASH_FILTER,
  SET_LANG_FILTER,
  SET_LIKESDOWN_FILTER,
  SET_LIKESUP_FILTER,
  SET_MIN_FOLLOWERS,
  SET_MIN_LIKES,
  SET_NEXT_ID,
  SET_SETTINGS,
  SET_USERNAME,
  TOGGLE_BRANDS,
  TOGGLE_MODAL
} from '../actions'

import { areaList } from '../../../../../extensions/CountryArea'

const initialState = {
  modalOpen: false,
  modalUser: '',
  brandsOpen: false,
  isLoading: true,
  catalog: [],
  settings: {
    countries: [],
    brands: [],
    aiCloud: [],
    hashCloud: [],
    langCloud: [],
    optionsAreas: []
  },
  minLikes: 0,
  minFollowers: 0,
  recentFirst: true,
  mostLikesFirst: false,
  mostLikesLast: false,
  selectedBrand: '',
  optionsAreas: [],
  selectedCountries: [],
  selectedAreas: [],
  selectedBrands: [],
  selectedLangs: [],
  selectedAi: [],
  selectedHash: [],
  pagestamp: 0
  // toJSON: () => {
  //   return { ...initialState, catalog: [] }
  // }
}

const handleBrands = (current, brand) => {
  if (current.includes(brand)) return current.filter((b) => b !== brand)

  const brands = Object.assign([], current)
  brands.push(brand)

  return brands
}

const concatCatalog = (current, items) => {
  const newCal = [...current, ...items]
  return newCal
}
const competitionReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_BRANDS: {
      return { ...state, brandsOpen: action.value }
    }
    case TOGGLE_MODAL: {
      return { ...state, modalOpen: action.value }
    }
    case SET_USERNAME: {
      return { ...state, modalUser: action.value }
    }
    case LOADING: {
      return { ...state, isLoading: true }
    }
    case LOADING_DONE: {
      return { ...state, isLoading: false }
    }
    case SET_CATALOG: {
      return { ...state, catalog: action.value }
    }
    case ADD_CATALOG: {
      return { ...state, catalog: concatCatalog(state.catalog, action.value) }
    }
    case SET_SETTINGS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          brands: action.value.brands,
          aiCloud: action.value.aiCloud.map((m) => m.label),
          hashCloud: action.value.hashCloud.map((m) => m.label),
          langCloud: action.value.langCloud.map((m) => m.label),
          countries: action.value.countries.map((m) => m.label),
          optionsAreas: action.value.areas.map((m) => m.label)
        }
      }
    }
    case SET_BRAND_FILTER: {
      return {
        ...state,
        selectedBrands: action.value //handleBrands(state.selectedBrands, action.value),
      }
    }
    case SET_AREA_FILTER: {
      return {
        ...state,
        selectedAreas: action.value //handleBrands(state.selectedBrands, action.value),
      }
    }
    case SET_HASH_FILTER: {
      return {
        ...state,
        selectedHash: action.value //handleBrands(state.selectedBrands, action.value),
      }
    }
    case SET_COUNTRY_FILTER: {
      return {
        ...state,
        selectedCountries: action.value
      }
    }
    case SET_LANG_FILTER: {
      return {
        ...state,
        selectedLangs: action.value //handleBrands(state.selectedBrands, action.value),
      }
    }
    case SET_AI_FILTER: {
      return {
        ...state,
        selectedAi: action.value //handleBrands(state.selectedBrands, action.value),
      }
    }
    case SET_LIKESUP_FILTER: {
      return { ...state, mostLikesFirst: action.value }
    }
    case SET_LIKESDOWN_FILTER: {
      return { ...state, mostLikesLast: action.value }
    }
    case SET_NEXT_ID: {
      return { ...state, pagestamp: action.value }
    }
    case SET_MIN_FOLLOWERS: {
      return { ...state, minFollowers: action.value }
    }
    case SET_MIN_LIKES: {
      return { ...state, minLikes: action.value }
    }
    default:
      return state
  }
}

export default competitionReducer
