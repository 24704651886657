import request from '../../shared/lib/request'
const rootApiUrl = process.env.REACT_APP_API_URL

function get(args = '') {
  return request({
    url: `${rootApiUrl}/crawl/data/${args}`,
    method: 'GET'
  })
}

function continueGet(continuationObject) {
  return request({
    url: `${rootApiUrl}/crawl/`,
    method: 'POST',
    data: continuationObject
  })
}

function attached(args = '') {
  return request({
    url: `${rootApiUrl}/crawl/attached/internalId/${args}`,
    method: 'GET'
  })
}
function assign(mediaId, giftId, mediaType) {
  return request({
    url: `${rootApiUrl}/crawl/${mediaId}/gift/${giftId}/type/${mediaType}`,
    method: 'POST',
    data: {}
  })
}

function remove(mediaId, mediaType) {
  return request({
    url: `${rootApiUrl}/crawl/${mediaId}/removeGift/type/${mediaType}`,
    method: 'POST',
    data: {}
  })
}

function dismiss(mediaId, mediaType) {
  return request({
    url: `${rootApiUrl}/crawl/${mediaId}/dismiss/type/${mediaType}`,
    method: 'POST',
    data: {}
  })
}

function search(sarchObj) {
  return request({
    url: `${rootApiUrl}/crawl/search`,
    method: 'POST',
    data: sarchObj
  })
}

const CrawlService = {
  get,
  search,
  continueGet,
  attached,
  assign,
  remove,
  dismiss
}

export default CrawlService
