export const CONNECTION_ESTABLISHED = 'notifHub/CONNECTION_ESTABLISHED'

import NotificationService from '../../../services/api/notification'
export const INBOUND_NOTIFICATION = 'notifHub/INBOUND_NOTIFICATION'
export const SET_NOTIFICATION = 'notifHub/SET_NOTIFICATION'
export const setCnId = (value) => {
  return (dispatch) => dispatch({ type: CONNECTION_ESTABLISHED, value })
}

export const setLastMsg = (value) => {
  return (dispatch) => dispatch({ type: INBOUND_NOTIFICATION, value })
}

export const fetchNotifications = () => {
  return (dispatch) => {
    NotificationService.get().then(({ data }) => {
      dispatch({ type: SET_NOTIFICATION, value: data })
    })
  }
}

export const markAllRead = () => {
  return (dispatch, getState) => {
    const store = getState().notification.notif
    const notifications = store.notifications.map((n) => {
      return { ...n, new: false }
    })
    NotificationService.setAllRead(notifications.map((n) => n.id))
    dispatch({ type: SET_NOTIFICATION, value: notifications })
  }
}

export const markOneRead = (id) => {
  return (dispatch, getState) => {
    const store = getState().notification.notif
    const notifications = store.notifications.map((n) => {
      return n.id === id ? { ...n, new: false } : n
    })

    NotificationService.setRead(id)
    dispatch({ type: SET_NOTIFICATION, value: notifications })
  }
}

export const markAllViewed = () => {
  return (dispatch, getState) => {
    const store = getState().notification.notif
    const notifications = store.notifications.map((n) => {
      return { ...n, viewed: true }
    })

    NotificationService.setViewed()
    dispatch({ type: SET_NOTIFICATION, value: notifications })
  }
}

export const markViewedByType = (type) => {
  return (dispatch, getState) => {
    const store = getState().notification.notif
    const notifications = store.notifications.map((n) => {
      return { ...n, viewed: true }
    })

    NotificationService.setViewedByType(type)
    dispatch({ type: SET_NOTIFICATION, value: notifications })
  }
}
