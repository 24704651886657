/* eslint-disable no-empty */
/* eslint-disable no-use-before-define */
import ChatService from '../../../services/api/chat'
import axios from 'axios'
export const CHAT_RECEIVE_SIGNAL = 'CHAT_RECEIVE_SIGNAL'
export const CHAT_RECEIVE_TYPING = 'CHAT_RECEIVE_TYPING'

export const chatReceivesMessage = (message) => {
  return (dispatch) => {
    dispatch({
      type: CHAT_RECEIVE_SIGNAL,
      msgs: message
    })
  }
}

export const chatReceivesTyping = (message) => {
  return (dispatch) => {
    dispatch({
      type: CHAT_RECEIVE_TYPING,
      message
    })
  }
}

export const chatEndsTyping = (message) => {
  const newmsg = { userPk: message.userPk, event: 'USER_PAUSE_TYPING' }
  return (dispatch) => {
    dispatch({
      type: CHAT_RECEIVE_TYPING,
      message: newmsg
    })
  }
}

export const getChats = () => {
  return (dispatch) => {
    ChatService.get()
      .then((response) => {
        dispatch({
          type: 'GET_CONTACTS',
          contacts: response.data.contacts,
          chats: makeChatMapping(response.data.chats)
        })
      })
      .then((response) => {
        dispatch(getContactChats())
      })
      .then((response) => {
        dispatch(endGlobalLoad())
      })
      .catch((err) => console.log(err))
  }
}

export const makeChatMapping = (chats) => {
  const chatlog = {}
  chats.forEach((chat) => {
    chatlog[chat.uid] = chat
  })
  return chatlog
}

export const startChatLoad = () => {
  return (dispatch) => {
    dispatch({
      type: 'START_CHAT_LOAD'
    })
  }
}

export const endChatLoad = () => {
  return (dispatch) => {
    dispatch({
      type: 'END_CHAT_LOAD'
    })
  }
}

export const startGlobalLoad = () => {
  return (dispatch) => {
    dispatch({
      type: 'START_GLOBAL_LOAD'
    })
  }
}

export const endGlobalLoad = () => {
  return (dispatch) => {
    dispatch({
      type: 'END_GLOBAL_LOAD'
    })
  }
}

export const getContactChats = () => {
  return (dispatch) => {
    ChatService.getContacts()
      .then((response) => {
        dispatch({
          type: 'GET_CHAT_CONTACTS',
          chats: response.data
        })
      })
      .catch((err) => console.log(err))
  }
}

export const togglePinned = (id, value) => {
  return (dispatch) => {
    axios
      .post('/api/apps/chat/set-pinned/', {
        contactId: id,
        value
      })
      .then((response) => {
        dispatch({
          type: 'SET_PINNED',
          id,
          value
        })
      })
      .catch((err) => console.log(err))
  }
}

export const sendMessage = (id, threadId, text) => {
  if (text.length > 0) {
    return (dispatch) => {
      const newMsg = {
        textContent: text,
        isSent: true,
        isSeen: false,
        time: new Date().toString()
      }
      if (!threadId) {
        ChatService.post({
          contactId: id,
          message: newMsg,
          threadId
        }).then((response) => {
          dispatch({
            type: 'SEND_MESSAGE',
            msg: newMsg,
            id,
            threadId: response.data.threadId,
            text
          })
        })
      } else {
        dispatch({
          type: 'SEND_MESSAGE',
          msg: newMsg,
          id,
          threadId,
          text
        })
        ChatService.post({
          contactId: id,
          message: newMsg,
          threadId
        })
          .then((response) => {
            //dispatch(fetchThread(threadId))
          })
          .catch((err) => console.log(err))
      }
    }
  } else {
  }
}

export const changeStatus = (status) => {
  return (dispatch) => {
    dispatch({
      type: 'CHANGE_STATUS',
      status
    })
  }
}

export const searchContacts = (query) => {
  return (dispatch) => {
    dispatch({
      type: 'SEARCH_CONTACTS',
      query
    })
  }
}

export const searchContactsRemote = (query) => {
  return (dispatch) => {
    if (!query || query.length === 1) return
    ChatService.search(query)
      .then((response) => {
        dispatch({
          type: 'SEARCH_CONTACTS_REMOTE',
          query,
          contacts: response.data
        })
      })
      .catch((err) => console.log(err))
  }
}

export const fetchThread = (id) => {
  return (dispatch) => {
    ChatService.getThread(id)
      .then((response) => {
        dispatch({
          type: 'GET_THREAD',
          msgs: response.data
        })
      })
      .catch((err) => console.log(err))
  }
}

export const markSeenAllMessages = (id) => {
  return (dispatch) => {
    axios
      .post('/api/apps/chat/mark-all-seen/', {
        contactId: id
      })
      .then((response) => {
        dispatch({
          type: 'MARK_AS_SEEN',
          id
        })
      })
      .catch((err) => console.log(err))
  }
}
