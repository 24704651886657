// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import AccountsService from '../../../../../../services/api/accounts'
import DashService from '../../../../../../services/api/dashboard'
import GrantsService from '../../../../../../services/api/grants'
import OrgaService from '../../../../../../services/api/orga'
// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await OrgaService.get()
  return response.data.users
})

export const getData = createAsyncThunk('appUsers/getData', async (params) => {
  const response = await axios.get('/api/users/list/data', params)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})

export const getAccounts = createAsyncThunk(
  'appUser/getAccounts',
  async (id) => {
    const response = await DashService.latestUsers(id)

    return response.data
  }
)

export const cancelGrants = createAsyncThunk(
  'appUsers/cancelGrants',
  async () => {
    return true
  }
)

export const getGrants = createAsyncThunk('appUsers/getGrants', async (id) => {
  const grants = await GrantsService.byList(id)

  return grants.data.sort((a, b) => a.id > b.id)
})

export const saveGrants = createAsyncThunk(
  'appUsers/saveGrants',
  async (params, { getState }) => {
    const store = getState().users

    await GrantsService.upsert(store.grants)
  }
)

export const setGrant = createAsyncThunk(
  'appUsers/setGrant',
  async ({ id, property, value }, { getState }) => {
    const store = getState().users
    const newGrant = Object.assign(
      {},
      store.grants.find((f) => f.beneficiary === id)
    )
    const stateGrants = Object.assign([], store.grants)
    const finalArr = []

    newGrant[property] = value

    for (let index = 0; index < stateGrants.length; index++) {
      const element = stateGrants[index]
      if (element.beneficiary === id) {
        finalArr.push(newGrant)
      } else {
        finalArr.push(element)
      }
    }

    return finalArr
  }
)

export const getUser = createAsyncThunk(
  'appUsers/getUser',
  async (id, { dispatch }) => {
    const response = await OrgaService.getById(id)
    await dispatch(getAccounts(response.data.user.email))
    await dispatch(getGrants(response.data.user.email))

    return response.data.user
  }
)

export const addUser = createAsyncThunk(
  'appUsers/addUser',
  async (user, { dispatch, getState }) => {
    await axios.post('/apps/users/add-user', user)
    await dispatch(getData(getState().users.params))
    await dispatch(getAllData())

    return user
  }
)

export const deleteUser = createAsyncThunk(
  'appUsers/deleteUser',
  async (id) => {
    await OrgaService.del(id)
  }
)
export const updateUserInfo = createAsyncThunk(
  'appUsers/editUser',
  async (body) => {
    await OrgaService.editUser(body)
  }
)

export const suspendUser = createAsyncThunk(
  'appUsers/suspendUser',
  async (id) => {
    await OrgaService.suspend(id)
  }
)

export const liftUser = createAsyncThunk('appUsers/liftUser', async (id) => {
  await OrgaService.lift(id)
})

export const getCalendars = createAsyncThunk(
  'appUsers/getCalendars',
  async () => {
    const calendars = await AccountsService.get()
    return calendars.data
  }
)

export const setCalColor = createAsyncThunk(
  'appUsers/setCalColor',
  async (val, { getState }) => {
    const cal = Object.assign({}, getState().users.myCalendar)
    cal.color = val.hex
    await AccountsService.patchColor({
      color: val.hex,
      email: cal.email,
      id: cal.email
    })

    return cal
  }
)

export const setShowAvatars = createAsyncThunk(
  'appUsers/setShowAvatars',
  async (val, { getState }) => {
    const cal = Object.assign({}, getState().users.myCalendar)
    await AccountsService.patchAccount({
      target: cal.email,
      property: 'ShowAvatars',
      logicValue: val
    })

    return cal
  }
)

export const setEditButton = createAsyncThunk(
  'appUsers/showEdit',
  async (val, { getState }) => {
    const cal = Object.assign({}, getState().users.myCalendar)

    await AccountsService.patchAccount({
      target: cal.email,
      property: 'ShowEdit',
      logicValue: val
    })

    return cal
  }
)
export const setNewTab = createAsyncThunk(
  'appUsers/setNewTab',
  async (val, { getState }) => {
    const cal = Object.assign({}, getState().users.myCalendar)

    await AccountsService.patchAccount({
      target: cal.email,
      property: 'OpenTabForInf',
      logicValue: val
    })

    return cal
  }
)

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    latestUsers: [],
    loadingUser: true,
    calendars: {},
    loadingCal: true,
    loadingGrants: true,
    saveGrantSpinner: false,
    myCalendar: {},
    patchColorLoading: false,
    grants: [],
    backupGrants: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.pending, (state) => {
        state.loadingUser = true
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loadingUser = false
        state.selectedUser = action.payload
      })
      .addCase(getAccounts.fulfilled, (state, action) => {
        state.latestUsers = action.payload
      })
      .addCase(getCalendars.pending, (state) => {
        state.loadingCal = true
      })
      .addCase(getCalendars.fulfilled, (state, action) => {
        state.loadingCal = false
        state.calendars = action.payload.filter(
          (c) => c.email !== state.selectedUser.email
        )
        state.myCalendar = action.payload.find(
          (c) => c.id === state.selectedUser.email
        )
      })
      .addCase(setCalColor.pending, (state) => {
        state.patchColorLoading = true
      })
      .addCase(setCalColor.fulfilled, (state, action) => {
        state.patchColorLoading = false
        state.myCalendar = action.payload
      })
      .addCase(getGrants.pending, (state) => {
        state.loadingGrants = true
      })
      .addCase(getGrants.fulfilled, (state, action) => {
        state.grants = action.payload
        state.backupGrants = action.payload
        state.loadingGrants = false
      })
      .addCase(setGrant.fulfilled, (state, action) => {
        state.grants = action.payload
      })
      .addCase(cancelGrants.fulfilled, (state) => {
        state.grants = state.backupGrants
      })
      .addCase(saveGrants.pending, (state) => {
        state.saveGrantSpinner = true
      })
      .addCase(saveGrants.rejected, (state) => {
        state.saveGrantSpinner = false
      })
      .addCase(saveGrants.fulfilled, (state) => {
        state.saveGrantSpinner = false
      })
      .addCase(suspendUser.fulfilled, (state) => {
        state.selectedUser.block = true
      })
      .addCase(liftUser.fulfilled, (state) => {
        state.selectedUser.block = false
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.selectedUser.deleted = true
      })
      .addCase(setShowAvatars.fulfilled, (state) => {
        state.myCalendar.showAvatars = !state.myCalendar.showAvatars
      })
      .addCase(setNewTab.fulfilled, (state) => {
        state.myCalendar.openTabForInf = !state.myCalendar.openTabForInf
      })
      .addCase(setEditButton.fulfilled, (state) => {
        state.myCalendar.showEdit = !state.myCalendar.showEdit
      })
  }
})

export default appUsersSlice.reducer
