export const ED_LOADING = 'ED_LOADING'
export const ED_SET_NETWORK = 'ED_SET_NETWORK'
export const ED_LOAD_USER = 'ED_LOAD_USER'
export const ED_LOAD_COUNTRIES = 'ED_LOAD_COUNTRIES'
export const ED_LOAD_ACCOUNTS = 'ED_LOAD_ACCOUNTS'
export const ED_SET_MANAGER = 'ED_SET_MANAGER'
export const ED_SET_CREATE = 'ED_SET_CREATE'
export const ED_UPDATE_TEXT = 'ED_UPDATE_TEXT'
export const ED_SET_ACCMANAGER = 'ED_SET_ACCMANAGER'
export const ED_SAVE = 'ED_SAVE'
export const ED_NET_MODAL_STATE = 'ED_NET_MODAL_STATE'
export const ED_NET_ADD_STATE = 'ED_NET_ADD_STATE'
export const ED_PUSH_NETWORK = 'ED_PUSH_NETWORK'
export const ED_NET_MODAL_USERNAME = 'ED_NET_MODAL_USERNAME'
export const ED_DELSET_NETWORK = 'ED_DELSET_NETWORK'
export const OPERATION_CHANGE = 'OPERATION_CHANGE'
export const INFO_LOADING = 'INFO_LOADING'
export const LOAD_ERROR = 'LOAD_ERROR'

import AccountsService from '../../../../../services/api/accounts'
import CountriesService from '../../../../../services/api/countries'
import UsersService from '../../../../../services/api/users'
import editUserReducer from './reducer'

export const loadingInfo = (value) => {
  return (dispatch) => {
    dispatch({ type: INFO_LOADING, value })
  }
}

export const loading = (state) => {
  return (dispatch) => {
    dispatch({ type: ED_LOADING, value: state })
  }
}

export const setNetwork = (net) => {
  return (dispatch) => {
    dispatch({ type: ED_SET_NETWORK, value: net })
  }
}

// general tab
export const updateTextProp = (prop, value) => {
  return (dispatch) => {
    dispatch({ type: ED_UPDATE_TEXT, prop, value })
  }
}

export const updateAccountManager = (value) => {
  return (dispatch) => {
    dispatch({ type: ED_SET_ACCMANAGER, value })
  }
}

export const save = (notifySuccess, notifyError) => {
  return (dispatch, getState) => {
    const store = getState().editUserReducer
    UsersService.post(store.user)
      .then((result) => notifySuccess())
      .catch(() => notifyError())
  }
}

export const boot = (id, network, user, notifyServErr) => {
  return (dispatch, getState) => {
    const authStore = getState().auth
    const toOne = setTimeout(() => {
      dispatch(loadingInfo('Waiting answer from social network...'))
    }, 4000)
    const toTwo = setTimeout(() => {
      dispatch(loadingInfo('Retrying 1/3 calling social network...'))
    }, 8000)
    const toTh = setTimeout(() => {
      dispatch(loadingInfo('Retrying 2/3 calling social network...'))
    }, 12000)
    const toF = setTimeout(() => {
      dispatch(loadingInfo('Retrying 3/3 calling social network...'))
    }, 18000)
    dispatch(loading(true))
    UsersService.get(id, network)
      .then(({ data }) => {
        clearTimeout(toOne)
        clearTimeout(toTwo)
        clearTimeout(toTh)
        clearTimeout(toF)
        if (data) {
          if (data.lifeCountry === null) {
            data.lifeCountry = data.country
          }
          if (data.accountManager === null) {
            data.accountManager = user.email
            dispatch(updateAccountManager(user.email))
          }
          if (data.accountManager === user.email) {
            dispatch({ type: ED_SET_MANAGER, value: true })
          } else if (
            data.accountManager === null &&
            data.accountManager !== user.email
          ) {
            dispatch({ type: ED_SET_CREATE, value: true })
            dispatch({ type: ED_SET_MANAGER, value: true })
          } else {
            dispatch({ type: ED_SET_MANAGER, value: false })
          }
          if (data.networks === null) {
            data.networks = [
              {
                usernameInNetwork: data.username,
                userIdInNetwork: data.uid.toString(),
                systemTypeOfId: '',
                networkName: network,
                isSourceNetwork: true,
                followerCount: data.followerCount,
                followingCount: data.followingCount,
                usertagsCount: data.usertagsCount,
                mediaCount: data.mediaCount
              }
            ]
          } else {
            dispatch(
              setNetwork(
                data.networks.filter((n) => n.isSourceNetwork)[0]?.networkName
              )
            )
          }
        }
        dispatch({ type: ED_LOAD_USER, value: data })
        dispatch(loading(false))
        dispatch(loadingInfo(''))
      })
      .catch((e) => {
        clearTimeout(toOne)
        clearTimeout(toTwo)
        clearTimeout(toTh)
        clearTimeout(toF)

        dispatch(loadingInfo('There was an error loading the data'))
        dispatch({ type: LOAD_ERROR, value: true })
        dispatch(loading(false))

        notifyServErr()
      })
      // .then(() => {
      //   const countries = authStore.countries

      //   const promise = new Promise((resolve, reject) => {
      //     resolve(authStore.countries)
      //   })

      //   const myPromise = countries?.countries?.length
      //     ? promise
      //     : CountriesService.get()

      //   myPromise
      //     .then((result) => {
      //       const resultObj = result.countries?.length
      //         ? countries?.countries
      //         : result.data
      //       const mapped = resultObj.map((c) => {
      //         return { value: c.value, label: c.label }
      //       })
      //       dispatch({ type: ED_LOAD_COUNTRIES, value: mapped })
      //     })
      //     .then(() => {
      //       AccountsService.get()
      //         .then((result) => {
      //           const mapped = result.data.map((c) => {
      //             return { value: c.email, label: c.fullName }
      //           })
      //           dispatch({ type: ED_LOAD_ACCOUNTS, value: mapped })
      //         })
      //         .then(() => {
      //           dispatch(loading(false))
      //         })
      //     })
      // })
      .catch((err) => {})
  }
}
// network modal
export const pushNetwork = (network) => {
  return (dispatch) => {
    dispatch({ type: ED_PUSH_NETWORK, value: network })
  }
}

export const remNetwork = (name, id) => {
  return (dispatch, getState) => {
    const store = getState().editUserReducer
    const currNets = store.user.networks
    const nextNets = []
    for (let index = 0; index < currNets.length; index++) {
      const currNet = currNets[index]
      if (
        currNet.networkName !== name ||
        (id !== undefined && currNet.userIdInNetwork !== id)
      ) {
        nextNets.push(currNet)
      }
    }
    dispatch({ type: ED_DELSET_NETWORK, value: nextNets })
  }
}

export const toggleNetworkModal = (
  state = null,
  network = null,
  username = ''
) => {
  return (dispatch, getState) => {
    const store = getState().editUserReducer
    if (username !== '') {
      dispatch({
        type: ED_NET_MODAL_USERNAME,
        value: username
      })
    }
    dispatch({
      type: ED_NET_MODAL_STATE,
      value: state ?? !store.networkModalState
    })
    if (network) {
      dispatch({ type: ED_NET_ADD_STATE, value: network })
    }
  }
}

export const addOperation = (operations, id) => {
  return (dispatch, getState) => {
    const store = getState().editUserReducer
    const reducedAct = operations.find((o) => o.id === id)
    if (reducedAct) {
      reducedAct.AddedToUserDate = new Date()
    }
    if (store.user.operations?.length) {
      const allActs = [...store.user.operations, reducedAct]
      dispatch({ type: OPERATION_CHANGE, value: allActs })
    } else {
      dispatch({ type: OPERATION_CHANGE, value: [reducedAct] })
    }
  }
}

export const removeOperation = (operations, id) => {
  return (dispatch, getState) => {
    const store = getState().editUserReducer
    const reduced = store.user.operations.filter((o) => o.id !== id)
    dispatch({ type: OPERATION_CHANGE, value: reduced })
  }
}
