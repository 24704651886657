import AccountsService from '../../../services/api/accounts'
import CountriesService from '../../../services/api/countries'
import ProductsService from '../../../services/api/products'
// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig

export const getProducts = () => (dispatch, _getState) => {
  const state = _getState().auth
  if (state.products.length) return
  ProductsService.getGroupped().then((response) => {
    if (response.data) {
      dispatch({ type: 'config/products', value: response.data })
    }
  })
}

export const getOrgAccounts = (token) => (dispatch) => {
  AccountsService.get(token).then((response) => {
    if (response.data) {
      dispatch({ type: 'config/orgAccs', value: response.data })
    }
  })
}

export const getOrgCountries = () => (dispatch) => {
  CountriesService.getAreasFull().then((response) => {
    if (response.data) {
      dispatch({ type: 'config/countries', value: response.data })
    }
  })
  // CountriesService.getTargets().then((response) => {
  //   if (response.data) {
  //     dispatch({ type: 'config/objectives', value: response.data })
  //   }
  // })
}

export const getObjectives = () => (dispatch) => {}

export const setObjectives = (objectives) => (dispatch) => {}

// ** Handle User Login
export const handleLogin = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'LOGIN',
      data,
      config,
      [config.storageTokenKeyName]: data[config.storageTokenKeyName],
      [config.storageRefreshTokenKeyName]:
        data[config.storageRefreshTokenKeyName]
    })

    // ** Add to user, accessToken & refreshToken to localStorage
    localStorage.setItem('userData', JSON.stringify(data))
    localStorage.setItem(
      config.storageTokenKeyName,
      JSON.stringify(data.accessToken)
    )
    localStorage.setItem(
      config.storageRefreshTokenKeyName,
      JSON.stringify(data.refreshToken)
    )
  }
}

// ** Handle User Logout
export const handleLogout = () => {
  return (dispatch) => {
    dispatch({
      type: 'LOGOUT',
      [config.storageTokenKeyName]: null,
      [config.storageRefreshTokenKeyName]: null
    })

    // ** Remove user, accessToken & refreshToken from localStorage
    localStorage.removeItem('userData')
    localStorage.removeItem(config.storageTokenKeyName)
    localStorage.removeItem(config.storageRefreshTokenKeyName)
  }
}
