import request from '../../shared/lib/request'
const rootApiUrl = process.env.REACT_APP_API_URL

function post(body, username) {
  return request({
    url: `${rootApiUrl}/giveaway/${username}`,
    method: 'POST',
    data: body
  })
}

function ranges(body) {
  return request({
    url: `${rootApiUrl}/giveaway/all/range`,
    method: 'POST',
    data: body
  })
}

function update(body) {
  return request({
    url: `${rootApiUrl}/giveaway/update/${body.id}`,
    method: 'POST',
    data: body
  })
}
function search(body) {
  return request({
    url: `${rootApiUrl}/giveaway/search`,
    method: 'POST',
    data: body
  })
}
function get() {
  return request({
    url: `${rootApiUrl}/giveaway`
  })
}

function del(id) {
  return request({
    url: `${rootApiUrl}/giveaway/${id}`,
    method: 'DELETE'
  })
}

const GiveawayService = {
  search,
  get,
  ranges,
  post,
  update,
  del
}

export default GiveawayService
