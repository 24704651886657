// ** Initial State
const initialState = {
  loading: true,
  events: [],
  range: {},
  selectedEvent: {},
  selectedCalendars: [],
  calendars: []
}
const eventsMapper = (events, calendars) => {
  const evs = events.map((ev) => {
    const calendar = calendars.find((c) => c.id === ev.author)
    return {
      id: ev.id,
      title: ev.title,
      // start: new Date(ev.startDate).toISOString().split('T')[0],
      // end: new Date(ev.endDate).toISOString().split('T')[0],
      start: ev.startDate,
      end: ev.endDate,
      allDay: false, //ev.allDay,
      url: '',
      editable: false,
      color: calendar?.color,

      textColor: '#5b5b5c',
      extendedProps: {
        calendar: ev.author,
        guests: ev.usernames,
        location: ev.location,
        description: ev.description,
        network: ev.network,
        area: ev.area,
        followers: ev.followers,
        likesCount: ev.likesCount,
        commentsCount: ev.commentsCount,
        winnerSentDate: ev.winnerSentDate,
        winner: ev.winner,
        winnerGiftId: ev.winnerGiftId,
        winnerGiftErpId: ev.winnerGiftErpId
      }
    }
  })

  return evs
}
const gcalendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_CALENDARS':
      return { ...state, calendars: action.value }
    case 'FETCH_GIVEAWAYS':
      return { ...state, events: eventsMapper(action.events, state.calendars) }
    case 'MERGE_GIVEAWAYS':
      return { ...state, events: eventsMapper(action.events, state.calendars) }
    case 'ADD_GIVEAWAY':
      return { ...state }
    case 'REMOVE_GIVEAWAY':
      return { ...state }
    case 'UPDATE_GIVEAWAY':
      return { ...state }
    case 'UPDATE_FILTERS': {
      // ** Updates Filters based on action filter
      const filterIndex = state.selectedCalendars.findIndex(
        (i) => i === action.filter
      )
      if (state.selectedCalendars.includes(action.filter)) {
        state.selectedCalendars.splice(filterIndex, 1)
      } else {
        state.selectedCalendars.push(action.filter)
      }
      if (state.selectedCalendars.length === 0) {
        state.events.length = 0
      }
      return { ...state }
    }
    case 'UPDATE_ALL_FILTERS': {
      // ** Updates All Filters based on action value
      const value = action.value
      let selected = []
      if (value === true) {
        selected = state.calendars.map((c) => c.id)
      } else {
        selected = []
      }
      return { ...state, selectedCalendars: selected }
    }

    case 'SELECT_GIVEAWAY':
      return { ...state, selectedEvent: action.event }
    case 'SET_RANGE_GIVEAWAYS':
      return { ...state, range: action.value }
    case 'SET_GLOADER':
      return { ...state, loading: action.value }
    default:
      return state
  }
}

export default gcalendarReducer
