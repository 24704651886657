import request from '../../shared/lib/request'
const rootApiUrl = process.env.REACT_APP_API_URL

function byCreator(creatorId) {
  return request({
    url: `${rootApiUrl}/grants/creator/${creatorId}`,
    method: 'GET'
  })
}

function byBeneficiary(beneficiaryId) {
  return request({
    url: `${rootApiUrl}/grants/beneficiary/${beneficiaryId}`,
    method: 'GET'
  })
}

function byList(creatorId) {
  return request({
    url: `${rootApiUrl}/grants/list/${creatorId}`,
    method: 'GET'
  })
}

function upsert(body) {
  return request({
    url: `${rootApiUrl}/grants`,
    method: 'POST',
    data: body
  })
}

const GrantsService = {
  byCreator,
  byBeneficiary,
  byList,
  upsert
}

export default GrantsService
