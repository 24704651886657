import AccountsService from '../../../services/api/accounts'
import UsersService from '../../../services/api/users'

export const START_FETCH_DATA = 'userGrid/START_FETCH_DATA'
export const START_FETCH_PARTIAL_DATA = 'userGrid/START_FETCH_PARTIAL_DATA'
export const INIT_GRIDCOLUMN_API = 'userGrid/INIT_GRIDCOLUMN_API'
export const INIT_GRID_API = 'userGrid/INIT_GRID_API'
export const SET_DATA = 'userGrid/SET_DATA'
export const DONE_DATA_FETCH = 'userGrid/DONE_DATA_FETCH'
export const DONE_DATA_PARTIAL_FETCH = 'userGrid/DONE_DATA_PARTIAL_FETCH'

export const SIDE_SINCE_ALL_CHECKED = 'userGrid/SIDE_SINCE_ALL_CHECKED'
export const SIDE_SINCE_3M_CHECKED = 'userGrid/SIDE_SINCE_3M_CHECKED'
export const SIDE_SINCE_6M_CHECKED = 'userGrid/SIDE_SINCE_6M_CHECKED'
export const SIDE_SINCE_CUSTOM_CHECKED = 'userGrid/SIDE_SINCE_CUSTOM_CHECKED'

export const SIDE_GIFTED_SINCE_ALL_CHECKED =
  'userGrid/SIDE_GIFTED_SINCE_ALL_CHECKED'
export const SIDE_GIFTED_SINCE_3M_CHECKED =
  'userGrid/IDE_GIFTED_SINCE_3M_CHECKED'
export const SIDE_GIFTED_SINCE_6M_CHECKED =
  'userGrid/SIDE_GIFTED_SINCE_6M_CHECKED'
export const SIDE_GIFTED_SINCE_CUSTOM_CHECKED =
  'userGrid/SIDE_GIFTED_SINCE_CUSTOM_CHECKED'

export const SIDE_FOL_SET_MIN = 'userGrid/SIDE_FOL_SET_MIN'
export const SIDE_FOL_SET_MAX = 'userGrid/SIDE_FOL_SET_MAX'
export const SIDE_ER_SET_MIN = 'userGrid/SIDE_ER_SET_MIN'
export const SIDE_ER_SET_MAX = 'userGrid/SIDE_ER_SET_MAX'
export const SET_CNT_RESULTS = 'userGrid/SET_CNT_RESULTS'
export const SET_MAX_FOL_INLIST = 'userGrid/SET_MAX_FOL_INLIST'
export const SET_MAX_ENG_INLIST = 'userGrid/SET_MAX_ENG_INLIST'
export const SIDE_REM_ALL_CHECKED = 'userGrid/SIDE_REM_ALL_CHECKED'
export const SIDE_REM_GIFT_CHECKED = 'userGrid/SIDE_REM_GIFT_CHECKED'
export const SIDE_REM_MONEY_CHECKED = 'userGrid/SIDE_REM_MONEY_CHECKED'
export const SIDE_REM_FREE_CHECKED = 'userGrid/SIDE_REM_FREE_CHECKED'

export const SIDE_STAT_ALL_CHECKED = 'userGrid/SIDE_STAT_ALL_CHECKED'
export const SIDE_STAT_NOTREACHED_CHECKED =
  'userGrid/SIDE_STAT_NOTREACHED_CHECKED'
export const SIDE_STAT_RELENGAGED_CHECKED =
  'userGrid/SIDE_STAT_RELENGAGED_CHECKED'
export const SIDE_STAT_INRELATION_CHECKED =
  'userGrid/SIDE_STAT_INRELATION_CHECKED'
export const SIDE_STAT_NOREPLY_CHECKED = 'userGrid/SIDE_STAT_NOREPLY_CHECKED'
export const SIDE_STAT_BAN_CHECKED = 'userGrid/SIDE_STAT_BAN_CHECKED'
export const SET_NETWORKS = 'userGrid/SET_NETWORKS'
export const SHOW_LOADING_OVERLAY = 'userGrid/SHOW_LOADING_OVERLAY'

export const ROW_CHECKED = 'userGrid/ROW_CHECKED'

export const SET_TAGS = 'userGrid/SET_TAGS'
export const FILTER_TAG = 'userGrid/FILTER_TAG'
export const CLEAR_TAGS = 'userGrid/CLEAR_TAGS'
export const CHECKED_TAG = 'userGrid/CHECKED_TAG'
export const UNCHECKED_NETWORKS = 'userGrid/UNCHECKED_NETWORKS'
export const CLEAR_FILTERS = 'userGrid/CLEAR_FILTERS'
export const SET_ACCOUNTS = 'userGrid/SET_ACCOUNTS'

export const initGridApi = (value) => {
  return (dispatch) => dispatch({ type: INIT_GRID_API, value })
}

export const initGridColumnApi = (value) => {
  return (dispatch) => dispatch({ type: INIT_GRIDCOLUMN_API, value })
}

export const startFetchData = () => {
  return (dispatch) => dispatch({ type: START_FETCH_DATA })
}

export const startFetchPartialData = () => {
  return (dispatch) => dispatch({ type: START_FETCH_PARTIAL_DATA })
}

export const setData = (value) => {
  return (dispatch) => dispatch({ type: SET_DATA, value })
}

export const doneFetch = () => {
  return (dispatch) => dispatch({ type: DONE_DATA_FETCH })
}
export const donePartialFetch = () => {
  return (dispatch) => dispatch({ type: DONE_DATA_PARTIAL_FETCH })
}
export const checkedSinceAll = () => {
  return (dispatch) => dispatch({ type: SIDE_SINCE_ALL_CHECKED })
}

export const checkedSince3m = () => {
  return (dispatch) => dispatch({ type: SIDE_SINCE_3M_CHECKED })
}

export const checkedSince6m = () => {
  return (dispatch) => dispatch({ type: SIDE_SINCE_6M_CHECKED })
}

export const checkedSinceCustom = () => {
  return (dispatch) => dispatch({ type: SIDE_SINCE_CUSTOM_CHECKED })
}

export const checkedGiftSinceAll = () => {
  return (dispatch) => dispatch({ type: SIDE_GIFTED_SINCE_ALL_CHECKED })
}

export const checkedGiftSince3m = () => {
  return (dispatch) => dispatch({ type: SIDE_GIFTED_SINCE_3M_CHECKED })
}

export const checkedGiftSince6m = () => {
  return (dispatch) => dispatch({ type: SIDE_GIFTED_SINCE_6M_CHECKED })
}

export const checkedGiftSinceCustom = () => {
  return (dispatch) => dispatch({ type: SIDE_GIFTED_SINCE_CUSTOM_CHECKED })
}

export const sliderFolSetMin = (value) => {
  return (dispatch) => dispatch({ type: SIDE_FOL_SET_MIN, value })
}

export const sliderFolSetMax = (value) => {
  return (dispatch) => dispatch({ type: SIDE_FOL_SET_MAX, value })
}

export const sliderErSetMin = (value) => {
  return (dispatch) => dispatch({ type: SIDE_ER_SET_MIN, value })
}

export const sliderErSetMax = (value) => {
  return (dispatch) => dispatch({ type: SIDE_ER_SET_MAX, value })
}

export const setCountResultsFound = (value) => {
  return (dispatch) => dispatch({ type: SET_CNT_RESULTS, value })
}

export const setMaxFollowersInList = (value) => {
  return (dispatch) => dispatch({ type: SET_MAX_FOL_INLIST, value })
}

export const setMaxEngagement = (value) => {
  return (dispatch) => dispatch({ type: SET_MAX_ENG_INLIST, value })
}

export const checkedRemunerationAll = () => {
  return (dispatch) => dispatch({ type: SIDE_REM_ALL_CHECKED })
}

export const checkedRemunerationGifts = () => {
  return (dispatch) => dispatch({ type: SIDE_REM_GIFT_CHECKED })
}

export const checkedRemunerationMoney = () => {
  return (dispatch) => dispatch({ type: SIDE_REM_MONEY_CHECKED })
}

export const checkedRemunerationFree = () => {
  return (dispatch) => dispatch({ type: SIDE_REM_FREE_CHECKED })
}

export const checkedStatusAll = () => {
  return (dispatch) => dispatch({ type: SIDE_STAT_ALL_CHECKED })
}

export const checkedStatusNotReached = () => {
  return (dispatch) => dispatch({ type: SIDE_STAT_NOTREACHED_CHECKED })
}

export const checkedStatusRelEngaged = () => {
  return (dispatch) => dispatch({ type: SIDE_STAT_RELENGAGED_CHECKED })
}

export const checkedStatusInRel = () => {
  return (dispatch) => dispatch({ type: SIDE_STAT_INRELATION_CHECKED })
}

export const checkedStatusNoReply = () => {
  return (dispatch) => dispatch({ type: SIDE_STAT_NOREPLY_CHECKED })
}

export const checkedStatusBanned = () => {
  return (dispatch) => dispatch({ type: SIDE_STAT_BAN_CHECKED })
}

export const rowChecked = (value) => {
  return (dispatch) => dispatch({ type: ROW_CHECKED, value })
}

export const showLoadingOverlay = () => {
  return (dispatch) => dispatch({ type: SHOW_LOADING_OVERLAY })
}
export const clearTags = () => {
  return (dispatch) => dispatch({ type: CLEAR_TAGS })
}

export const checkedTags = (value) => (dispatch, _getState) => {
  dispatch({ type: CHECKED_TAG, value })
}

export const uncheckedNetworks = (value) => (dispatch, _getState) => {
  dispatch({ type: UNCHECKED_NETWORKS, value })
}

export const setTags = (db) => {
  const tagsCloud = []
  db.forEach((user) => {
    if (user.tags !== null) {
      user.tags.forEach((tag) => {
        const existing = tagsCloud.filter((c) => c.value === tag)
        if (existing.length) {
          const updatedExisting = existing[0]
          updatedExisting.count = updatedExisting.count + 1
          const excluded = Object.assign(
            [],
            tagsCloud.filter((c) => c.value !== tag)
          )
          excluded.push(updatedExisting)
        } else {
          tagsCloud.push({ value: tag, count: 1 })
        }
      })
    }
  })
  return tagsCloud
}

export const filterNetworks = (value) => (dispatch, _getState) => {
  const state = _getState().userGrid.userGrid
  const { rowData, filteredData } = state
  const newDb = []

  const listState = []

  // si aucun excluded, retourner tout
  if (state.sideBarOptions.excludedNetworks.length === 0) {
    dispatch({
      type: SET_TAGS,
      value: setTags(rowData)
    })

    dispatch({
      type: FILTER_TAG,
      value: rowData
    })
  }

  // si au moins 1 excluded, prendre dans la liste des utilisateurs, tout ceux qui contiennent au moins les networks cochés restants
  // donc pour celà, il faut, dans un nouveau tableau, retirer de all networks ceux qui sont décochés
  state.sideBarOptions.networks.forEach((network) => {
    if (!state.sideBarOptions.excludedNetworks.includes(network)) {
      listState.push(network)
    }
  })

  rowData.forEach((user) => {
    const networks = user.networks.map((n) => n.networkName)
    networks.forEach((network) => {
      if (listState.includes(network)) {
        if (!newDb.filter((exusr) => exusr.username === user.username).length) {
          newDb.push(user)
        }
      }
    })
  })

  dispatch({
    type: SET_TAGS,
    value: setTags(listState.length > 0 ? newDb : rowData)
  })

  dispatch({
    type: FILTER_TAG,
    value: listState.length > 0 ? newDb : rowData
  })
}

export const filterTags = (value) => (dispatch, _getState) => {
  const state = _getState().userGrid.userGrid
  const { rowData } = state

  const filteredData = []
  value.forEach((v) => {
    rowData.forEach((row) => {
      if (row.tags) {
        row.tags.forEach((tag) => {
          if (tag === v) {
            if (
              !filteredData.filter((user) => user.username === row.username)
                .length
            ) {
              filteredData.push(row)
            }
          }
        })
      }
    })
  })

  dispatch({
    type: FILTER_TAG,
    value: filteredData
  })
}

export const fetchPart = (id) => {
  return (dispatch, _getState) => {
    dispatch(startFetchPartialData())
    dispatch(setData([]))
    UsersService.getFor(id)
      .then((response) => {
        dispatch(setCountResultsFound(response.data.db.length))
        dispatch(setMaxFollowersInList(response.data.maxFlwr))
        dispatch(setMaxEngagement(response.data.maxEng))
        dispatch(setData(response.data.db))
        dispatch({ type: SET_TAGS, value: setTags(response.data.db) })
      })
      .then(() => dispatch(donePartialFetch()))
      .then(() => dispatch(clearTags()))
  }
}

export const socials = (users) => {
  const networks = []
  try {
    users.db.forEach((user) => {
      user.networks.forEach((network) => {
        if (!networks.includes(network.networkName)) {
          networks.push(network.networkName)
        }
      })
    })
  } catch (exception) {
    return []
  }

  return networks
}
export const quickUpdate = (username, property, value) => {
  return (dispatch) => {
    return UsersService.quickEdit({ username, property, value })
  }
}

export function fetchData(id) {
  return (dispatch, _getState) => {
    AccountsService.get()
      .then(({ data }) => {
        dispatch({ type: SET_ACCOUNTS, value: data })
      })
      .then(() => {
        dispatch(startFetchData())
        dispatch(startFetchPartialData())
        UsersService.getFor(id)
          .then((response) => {
            dispatch(setCountResultsFound(response.data.db.length))
            dispatch(setMaxFollowersInList(response.data.maxFlwr))
            dispatch(setMaxEngagement(response.data.maxEng))
            dispatch(setData(response.data.db))
            dispatch({ type: SET_TAGS, value: setTags(response.data.db) })
            dispatch({ type: SET_NETWORKS, value: socials(response.data) })
          })
          .then(() => dispatch(doneFetch()))
          .then(() => dispatch(donePartialFetch()))
          .then(() => dispatch(clearTags()))
      })
  }
}

export function clearFilters() {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILTERS })
  }
}
