import request from '../../shared/lib/request'
const rootApiUrl = process.env.REACT_APP_API_URL

function get() {
  return request({
    url: `${rootApiUrl}/countries`,
    method: 'GET'
  })
}

function getAreas() {
  return request({
    url: `${rootApiUrl}/countries/areas`,
    method: 'GET'
  })
}
function getTargets() {
  return request({
    url: `${rootApiUrl}/countries/targets`,
    method: 'GET'
  })
}

function postTargets(targets) {
  return request({
    url: `${rootApiUrl}/countries/targets`,
    method: 'POST',
    data: targets
  })
}

function getAreasFull() {
  return request({
    url: `${rootApiUrl}/countries/areas/full`,
    method: 'GET'
  })
}

function postAreas(countries) {
  return request({
    url: `${rootApiUrl}/countries`,
    method: 'POST',
    data: countries
  })
}

function postDefaultArea(area, replace) {
  return request({
    url: `${rootApiUrl}/countries/defaultArea`,
    method: 'POST',
    data: { area, replace }
  })
}

const CountriesService = {
  get,
  getAreas,
  getTargets,
  postTargets,
  getAreasFull,
  postAreas,
  postDefaultArea
}

export default CountriesService
