import {
  SIGNAL_CLEAN_COUNT,
  SIGNAL_RECEIVE_MSG
} from '../../actions/signal/index'

const initialState = {
  count: 0,
  lastMessage: {}
}

const signalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNAL_RECEIVE_MSG:
      return { ...state, count: state.count++, lastMessage: action.message }
    case SIGNAL_CLEAN_COUNT:
      return { ...state, count: 0 }
    default:
      return state
  }
}

export default signalReducer
