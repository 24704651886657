import request from '../../shared/lib/request'
const rootApiUrl = process.env.REACT_APP_API_URL

function get(username, network) {
  return request({
    url: `${rootApiUrl}/users/${username}/network/${network}`,
    method: 'GET'
  })
}

function create(username, network) {
  return network !== 'littleredbook'
    ? request({
        url: `${rootApiUrl}/users/${username}/network/${network}`,
        method: 'GET'
      })
    : request({
        url: `${rootApiUrl}/users/${username}/network/${network}?byId=true`,
        method: 'GET'
      })
}

function getFor(id) {
  return request({
    url: `${rootApiUrl}/users/account/${id}`,
    method: 'GET'
  })
}
function getForTransfer(id) {
  return request({
    url: `${rootApiUrl}/users/account/fullListing/${id}`,
    method: 'GET'
  })
}

function post(body) {
  return request({
    url: `${rootApiUrl}/users/`,
    method: 'POST',
    data: body
  })
}

function search(query) {
  return request({
    url: `${rootApiUrl}/users/search/${query}`,
    method: 'GET'
  })
}

function searchNet(query) {
  return request({
    url: `${rootApiUrl}/users/searchNet/${query}`,
    method: 'GET'
  })
}

function suggestions(pk, network) {
  return request({
    url: `${rootApiUrl}/users/${pk}/suggestions/${network}`,
    method: 'GET'
  })
}

function setLastContact(username) {
  return request({
    url: `${rootApiUrl}/users/setContacted/${username}`,
    method: 'POST'
  })
}

function quickEdit(body) {
  return request({
    url: `${rootApiUrl}/users/quickEdit/${body.username}`,
    method: 'POST',
    data: body
  })
}

function del(id) {
  return request({
    url: `${rootApiUrl}/users/${id}`,
    method: 'DELETE'
  })
}

const UsersService = {
  get,
  getFor,
  getForTransfer,
  post, //, update, delete, etc. ...
  setLastContact,
  search,
  searchNet,
  del,
  quickEdit,
  suggestions,
  create
}

export default UsersService
