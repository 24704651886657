import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import CrawlService from '../../../../../services/api/crawl'
import FileService from '../../../../../services/api/file'
import GiftsService from '../../../../../services/api/gifts'
import PostService from '../../../../../services/api/post'
import ScheduleService from '../../../../../services/api/schedule'
import UsersService from '../../../../../services/api/users'

export const init = createAsyncThunk(
  'viewInfluencer/init',
  async (param, { rejectWithValue }) => {
    const urlId = param.id

    const user = await UsersService.get(urlId, null).catch((e) => {
      throw rejectWithValue(e.data)
    })

    const network =
      user.data.networks.filter((n) => n.isSourceNetwork)[0]?.networkName ??
      user.data.networks[0] ??
      'instagram'

    const id = user.data.username
    const internalUserId = user.data.internalId
    const gifts = await GiftsService.getByUserInternalId(user.data.internalId)
    const medias = await CrawlService.attached(user.data.internalId)
    const comments = await PostService.getCommentsFor(id)
    const meetings = await ScheduleService.getByInfluencer(id)
    const files = await FileService.getFilesFor(user.data.internalId)

    const mergedMedias = [...medias.data.posts, ...medias.data.stories]

    const mediasWithGifts = mergedMedias.map((med) => {
      return { ...med, gift: gifts?.data.find((g) => g.id === med.giftId) }
    })

    return {
      user: user.data,
      gifts: gifts.data,
      medias: mediasWithGifts.sort(function (a, b) {
        return new Date(b.uploadDate) - new Date(a.uploadDate)
      }),
      comments: comments.data,
      meetings: meetings.data,
      files: files.data,
      network,
      internalUserId
    }
  }
)

export const fetchComments = createAsyncThunk(
  'viewInfluencer/fetchComments',
  async (id) => {
    const comments = await PostService.getCommentsFor(id)
    return comments.data
  }
)

export const addManualMedia = createAsyncThunk(
  'viewInfluencer/addManualMedia',
  async (media, { getState }) => {
    const store = getState().view
    const assignedGift = store.gifted?.find((g) => g.id === media.giftId)
    if (assignedGift) {
      media = Object.assign({}, media, { gift: assignedGift })
    }

    return [media, ...store.medias]
  }
)
export const refreshFiles = createAsyncThunk(
  'viewInfluencer/refreshFiles',
  async (id) => {
    const files = await FileService.getFilesFor(id)
    return files.data
  }
)

export const viewInfluencerSlice = createSlice({
  name: 'viewInfluencer',
  initialState: {
    id: '',
    data: {},
    gifted: [],
    files: [],
    networks: [],
    meetings: [],
    hasLoaded: false,
    medias: {},
    //queueLabel: t('viewContact.queue'),
    disableQ: false,
    accountManager: '',
    lock: false,
    openComModal: false,
    openMeetModal: false,
    openGiveawayModal: false,
    comments: [],
    network: 'instagram',
    activeHeaderTab: '0',
    internalUserId: ''
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(init.pending, (state, action) => {
        state.hasLoaded = false
      })
      .addCase(addManualMedia.fulfilled, (state, action) => {
        state.medias = action.payload
      })
      .addCase(init.rejected, (state, action) => {
        window.location = '/forbidden'
      })
      .addCase(init.fulfilled, (state, action) => {
        state.network = action.payload.network
        state.data = action.payload.user
        state.gifted = action.payload.gifts
        state.medias = action.payload.medias
        state.comments = action.payload.comments
        state.accountManager = action.payload.user.accountManager
        state.networks = action.payload.user.networks
        state.meetings = action.payload.meetings
        state.files = action.payload.files
        state.id = action.payload.user.username
        state.internalUserId = action.payload.internalUserId
        state.hasLoaded = true
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        state.comments = action.payload
      })
      .addCase(refreshFiles.fulfilled, (state, action) => {
        state.files = action.payload
      })
  }
})

export default viewInfluencerSlice.reducer
