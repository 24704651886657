import request from '../../shared/lib/request'
const rootApiUrl = process.env.REACT_APP_API_URL

function get(account = '') {
  return request({
    url: `${rootApiUrl}/chat/${account}`,
    method: 'GET'
  })
}

function getContacts(account = '') {
  if (account === '') {
    account = 'all'
  }
  return request({
    url: `${rootApiUrl}/chat/contacts/${account}`,
    method: 'GET'
  })
}

function getThread(args = '') {
  return request({
    url: `${rootApiUrl}/chat/thread/${args}`,
    method: 'GET'
  })
}

function post(body) {
  return request({
    url: `${rootApiUrl}/chat/`,
    method: 'POST',
    data: body
  })
}

function search(query) {
  return request({
    url: `${rootApiUrl}/chat/search/${query}`,
    method: 'GET'
  })
}

const ChatService = {
  get,
  getThread,
  getContacts,
  search,
  post //, update, delete, etc. ...
}

export default ChatService
