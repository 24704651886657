import {
    UPLOAD_FILE,
    SET_COMMENTS,
    SET_SOURCE,
    SET_GIFT,
    SET_VIEWS,
} from '../../actions/postModal/index'

const initialState = {
    postFile: '',
    comments: 0,
    views: 0,
    giftId: null,
    source: '',
}

const postModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPLOAD_FILE:
            return { ...state, postFile: action.value }
        case SET_VIEWS:
            return { ...state, views: action.value }
        case SET_COMMENTS:
            return { ...state, comments: action.value }
        case SET_SOURCE:
            return { ...state, source: action.value }
        case SET_GIFT:
            return { ...state, giftId: action.value }
        default:
            return state
    }
}

export default postModalReducer
