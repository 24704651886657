export const networksList = [
  'instagram',
  'tiktok',
  'youtube',
  'wechat',
  'littleredbook',
  'weibo',
  'bilibili',
  'naver',
  'douyin'
]
