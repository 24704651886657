import CompetitionService from '../../../../../../services/api/compet'
import SettingsService from '../../../../../../services/api/settings'

export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const TOGGLE_BRANDS = 'TOGGLE_BRANDS'
export const SET_USERNAME = 'SET_USERNAME'
export const LOADING = 'LOADING'
export const SET_CATALOG = 'SET_CATALOG'
export const ADD_CATALOG = 'ADD_CATALOG'
export const LOADING_DONE = 'LOADING_DONE'
export const SET_SETTINGS = 'SET_SETTINGS'
export const SET_BRAND_FILTER = 'SET_FILTER'
export const SET_LIKESUP_FILTER = 'SET_LIKESUP_FILTER'
export const SET_LIKESDOWN_FILTER = 'SET_LIKESDOWN_FILTER'
export const SET_NEXT_ID = 'SET_NEXT_ID'
export const SET_AREA_FILTER = 'discovery/SET_AREA_FILTER'
export const SET_MIN_FOLLOWERS = 'discovery/SET_MIN_FOLLOWERS'
export const SET_MIN_LIKES = 'discovery/SET_MIN_LIKES'
export const SET_HASH_FILTER = 'discovery/SET_HASH_FILTER'
export const SET_AI_FILTER = 'discovery/SET_AI_FILTER'
export const SET_LANG_FILTER = 'discovery/SET_LANG_FILTER'
export const SET_COUNTRY_FILTER = 'discovery/SET_COUNTRY_FILTER'

export const fetch = () => (dispatch, _getState) => {
  const state = _getState().competition
  dispatch({ type: LOADING })
  CompetitionService.posts({
    continuationToken: '',
    brands: state.selectedBrands.map((s) => s.value),
    areas: state.selectedAreas.map((s) => s.value),
    countries: state.selectedCountries.map((s) => s.value),
    languages: state.selectedLangs.map((s) => s.value),
    hashtags: state.selectedHash.map((s) => s.value),
    tags: state.selectedAi.map((s) => s.value),
    minLikes: state.minLikes,
    minFollowers: state.minFollowers,
    filter:
      state.mostLikesFirst || state.mostLikesLast
        ? 'LikesCount'
        : 'creationDate',
    asc: !!state.mostLikesLast
  })
    .then((result) => {
      console.log(result)
      dispatch({ type: SET_CATALOG, value: result.data.posts })
      dispatch({
        type: SET_NEXT_ID,
        value: result.data.token
      })
    })
    .then(() => {
      dispatch({ type: LOADING_DONE })
    })
}

export const toggleModal = () => (dispatch, _getState) => {
  const state = _getState().competition
  dispatch({ type: TOGGLE_MODAL, value: !state.modalOpen })
}

export const toggleBrands = () => (dispatch, _getState) => {
  const state = _getState().competition
  console.log('toggleBrands')
  dispatch({ type: TOGGLE_BRANDS, value: !state.brandsOpen })
}

export const setUsername = (value) => (dispatch) => {
  dispatch({ type: SET_USERNAME, value })
}

export const setNextId = (value) => (dispatch, _getState) => {
  dispatch({
    type: SET_NEXT_ID,
    value
  })
  dispatch(fetch())
}

export const switchBrand = (value) => (dispatch, _getState) => {
  dispatch({
    type: SET_BRAND_FILTER,
    value
  })
  dispatch({
    type: SET_NEXT_ID,
    value: 0
  })
  dispatch(fetch())
}

export const setHashValue = (value) => (dispatch) => {
  dispatch({ type: SET_HASH_FILTER, value })
}

export const setAiValue = (value) => (dispatch) => {
  dispatch({ type: SET_AI_FILTER, value })
}

export const setLangValue = (value) => (dispatch) => {
  dispatch({ type: SET_LANG_FILTER, value })
}

export const setCountryValue = (value) => (dispatch) => {
  dispatch({ type: SET_COUNTRY_FILTER, value })
}

export const setBrands = (value) => (dispatch) => {
  dispatch({ type: SET_BRAND_FILTER, value })
}

export const setAreas = (value) => (dispatch) => {
  dispatch({ type: SET_AREA_FILTER, value })
}

export const setMinFollowers = (value) => (dispatch) => {
  dispatch({ type: SET_MIN_FOLLOWERS, value: value.value })
}

export const setMinLikes = (value) => (dispatch) => {
  dispatch({ type: SET_MIN_LIKES, value: value.value })
}

export const clear = (value) => (dispatch, _getState) => {
  dispatch({
    type: SET_LIKESDOWN_FILTER,
    value: false
  })
  dispatch({
    type: SET_LIKESUP_FILTER,
    value: false
  })
  dispatch(fetch())
}

export const likesUp = (value) => (dispatch, _getState) => {
  dispatch({
    type: SET_LIKESDOWN_FILTER,
    value: false
  })
  dispatch({
    type: SET_LIKESUP_FILTER,
    value: true
  })
  dispatch(fetch())
}

export const likesDown = (value) => (dispatch, _getState) => {
  dispatch({
    type: SET_LIKESUP_FILTER,
    value: false
  })
  dispatch({
    type: SET_LIKESDOWN_FILTER,
    value: true
  })
  dispatch(fetch())
}

export const paginate = () => (dispatch, _getState) => {
  console.log('paginate')
  const state = _getState().competition

  //dispatch({ type: LOADING })
  CompetitionService.posts({
    continuationToken: state.pagestamp,
    areas: state.selectedAreas.map((s) => s.value),
    countries: state.selectedCountries.map((s) => s.value),
    languages: state.selectedLangs.map((s) => s.value),
    hashtags: state.selectedHash.map((s) => s.value),
    tags: state.selectedAi.map((s) => s.value),
    minLikes: state.minLikes,
    minFollowers: state.minFollowers,
    brands: state.selectedBrands.length
      ? state.selectedBrands.map((b) => b.value)
      : [],
    filter:
      state.mostLikesFirst || state.mostLikesLast
        ? 'LikesCount'
        : 'creationDate',
    asc: !!state.mostLikesLast
  })
    .then((result) => {
      console.log(result)
      if (result.data.posts.length) {
        dispatch({ type: ADD_CATALOG, value: result.data.posts })
        dispatch({
          type: SET_NEXT_ID,
          value: result.data.token
        })
      }
    })
    .then(() => {})
}

export const getData = (value) => (dispatch) => {
  dispatch({ type: LOADING })
  let brands
  SettingsService.brands('polene')
    .then((result) => {
      if (result.data.brands !== null) {
        if (result.data.brands.length) {
          dispatch({ type: SET_SETTINGS, value: result.data })
        }
      }
    })
    .then(() => {
      CompetitionService.posts({ continuationToken: '' })
        .then((result) => {
          console.log(result)
          if (result.data.posts.length) {
            dispatch({ type: SET_CATALOG, value: result.data.posts })
            dispatch({
              type: SET_NEXT_ID,
              value: result.data.token
            })
          }
        })
        .then(() => {
          dispatch({ type: LOADING_DONE })
        })
    })
}

const maxIdFn = (results) => {
  const resTab = results.map((s) => s.creationTimestamp)
  console.log(resTab, 'restab')

  return resTab[resTab.length - 1]
}
