import {
  FILTER_ID,
  FILTER_SKU,
  FILTER_USERNAME,
  MODAL_CONTENT,
  POP_MODAL,
  PUSH_TO_FILTERS,
  REM_FROM_BODY,
  REM_FROM_FILTERS,
  RESET,
  SELECT_ITEM,
  SET_ACCOUNTS,
  SET_AREA,
  SET_AREAS,
  SET_DATASET,
  SET_DATE_END,
  SET_DATE_START,
  SET_FETCH_ACCS_DONE,
  SET_FETCH_ACCS_START,
  SET_FETCH_DONE,
  SET_FETCH_START,
  SET_FILTER_ISTODAY,
  SET_LAST_READYON,
  SET_ORDER_STATUS,
  SET_OWNER,
  SET_SOURCE,
  SET_SP_DATE_START,
  SET_TOGGLE_ERP,
  SET_TOGGLE_OOS,
  SET_TOGGLE_SHP,
  TOGGLE_IGNORE_FILTERS
} from '../actions'

const initialState = {
  data: [],
  lastChangedId: null,
  selectedItems: [],
  searchValue: '',
  filteredData: [],
  filterBadges: [],
  accounts: [],
  areaOptions: [],
  searchSku: '',
  searchId: '',
  body: {
    sku: '',
    ignoreFilters: false,
    erpStatus: 2,
    ownerEmail: [],
    stockStatus: 0,
    areas: [],
    shippingStatus: 0,
    negotiatedBefore: null,
    negotiatedAfter: null
  },
  loadingData: true,
  loadingAccounts: true,
  popModal: false,
  modalContent: '',
  sender: ''
}

const remFromBody = (state, value) => {
  const body = state.body

  if (value.key === 'ownerEmail') {
    body[value.key] = body[value.key].filter((b) => b !== value.label)
  } else if (value.key === 'shippingAfter' || value.key === 'shippingBefore') {
    body[value.key] = null
  } else {
    body[value.key] = ''
  }

  return body
}

const removeFromFilters = (state, value) => {
  const filters = state.filterBadges
  const newArr = []
  filters.forEach((item) => {
    if (item.label !== value.label) {
      newArr.push(item)
    }
  })

  return newArr
}

const pushToFilters = (state, value) => {
  const filters = state.filterBadges
  const newArr = []

  filters.forEach((item) => {
    if (item.key !== value.key || item.key === 'ownerEmail') {
      if (item.label !== value.label) {
        newArr.push(item)
      }
    }
  }, value)

  newArr.push(value)

  return newArr
}

const manageGiftsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDER_STATUS: {
      return {
        ...state,
        data: action.value
      }
    }
    case TOGGLE_IGNORE_FILTERS: {
      return {
        ...state,
        body: { ...state.body, ignoreFilters: !state.body.ignoreFilters }
      }
    }
    case SELECT_ITEM: {
      return { ...state, selectedItems: action.value }
    }
    case SET_LAST_READYON: {
      return { ...state, lastChangedId: action.value }
    }
    case FILTER_SKU: {
      return {
        ...state,
        searchSku: action.value,
        body: { ...state.body, sku: action.value }
      }
    }
    case FILTER_ID: {
      return {
        ...state,
        searchId: action.value,
        body: { ...state.body, erpId: action.value }
      }
    }
    case FILTER_USERNAME: {
      return {
        ...state,
        searchValue: action.value,
        body: { ...state.body, recipientUsername: action.value }
      }
    }
    case SET_ACCOUNTS: {
      return { ...state, accounts: action.value }
    }
    case SET_TOGGLE_ERP: {
      return {
        ...state,
        body: { ...state.body, erpStatus: action.value }
      }
    }
    case SET_TOGGLE_OOS: {
      return {
        ...state,
        body: { ...state.body, stockStatus: action.value }
      }
    }
    case SET_TOGGLE_SHP: {
      return {
        ...state,
        body: { ...state.body, shippingStatus: action.value }
      }
    }
    case SET_OWNER: {
      return {
        ...state,
        body: {
          ...state.body,
          ownerEmail: action.value
        }
      }
    }
    case SET_AREA: {
      return {
        ...state,
        body: {
          ...state.body,
          areas: action.value
        }
      }
    }
    case SET_AREAS: {
      return {
        ...state,
        areaOptions: action.value
      }
    }
    case SET_FETCH_START: {
      return { ...state, loadingData: true }
    }
    case SET_FETCH_DONE: {
      return { ...state, loadingData: false }
    }
    case SET_FETCH_ACCS_START: {
      return { ...state, loadingAccounts: true }
    }
    case SET_FETCH_ACCS_DONE: {
      return { ...state, loadingAccounts: false }
    }
    case SET_DATASET: {
      return { ...state, data: action.value }
    }
    case SET_SOURCE: {
      return {
        ...state,
        sender: action.value,
        body: { ...state.body, sender: action.value }
      }
    }
    case SET_FILTER_ISTODAY: {
      return { ...state, body: { ...state.body, shippingAfter: action.value } }
    }
    case PUSH_TO_FILTERS: {
      return { ...state, filterBadges: pushToFilters(state, action.value) }
    }
    case REM_FROM_FILTERS: {
      return { ...state, filterBadges: removeFromFilters(state, action.value) }
    }
    case REM_FROM_BODY: {
      return { ...state, body: remFromBody(state, action.value) }
    }
    case SET_SP_DATE_START: {
      return {
        ...state,
        body: { ...state.body, shippingAfter: action.value }
      }
    }
    case SET_DATE_START: {
      return {
        ...state,
        body: { ...state.body, negotiatedAfter: action.value }
      }
    }
    case SET_DATE_END: {
      return {
        ...state,
        body: { ...state.body, negotiatedBefore: action.value }
      }
    }
    case POP_MODAL: {
      return { ...state, popModal: action.value }
    }
    case MODAL_CONTENT: {
      return { ...state, modalContent: action.value }
    }
    case RESET: {
      return { ...initialState }
    }
    default:
      return state
  }
}

export default manageGiftsReducer
