import request from '../../shared/lib/request'
const rootApiUrl = process.env.REACT_APP_API_URL

function post(body) {
  return request({
    url: `${rootApiUrl}/post`,
    method: 'POST',
    data: body
  })
}

function getCommentsFor(username) {
  return request({
    url: `${rootApiUrl}/post/comment/${username}`
  })
}

function deleteComment(id, influencer) {
  return request({
    url: `${rootApiUrl}/post/comment/delete/${id}/influencer/${influencer}`,
    method: 'DELETE'
  })
}

function postComment(body) {
  return request({
    url: `${rootApiUrl}/post/comment`,
    method: 'POST',
    data: body
  })
}
const PostService = {
  postComment,
  getCommentsFor,
  deleteComment,
  post //, update, delete, etc. ...
}

export default PostService
