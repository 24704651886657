// ** Logo
import logo from '@src/assets/images/logo/logo.png'

const SpinnerComponent = ({ info, spin = true }) => {
  return (
    <div className="fallback-spinner vh-100">
      <img className="fallback-logo" src={logo} alt="logo" />

      <div className="loading">
        {spin ? (
          <>
            <div className="effect-1 effects"></div>
            <div className="effect-2 effects"></div>
            <div className="effect-3 effects"></div>
          </>
        ) : (
          ''
        )}
        <div className="loadingText">{info}</div>
      </div>
    </div>
  )
}

export default SpinnerComponent
