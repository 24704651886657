import request from '../../shared/lib/request'
const rootApiUrl = process.env.REACT_APP_API_URL

function get(token) {
  return request({
    url: `${rootApiUrl}/accounts`,
    method: 'GET',
    token
  })
}

function searchables() {
  return request({
    url: `${rootApiUrl}/accounts/settings/searchable`,
    method: 'GET'
  })
}

function transfer(body) {
  return request({
    url: `${rootApiUrl}/accounts/transfer`,
    method: 'POST',
    data: body
  })
}

function patchColor(body) {
  return request({
    url: `${rootApiUrl}/accounts/patch/color`,
    method: 'POST',
    data: body
  })
}

function patchAccount(body) {
  return request({
    url: `${rootApiUrl}/accounts/toggle`,
    method: 'POST',
    data: body
  })
}

const AccountsService = {
  get,
  searchables,
  transfer,
  patchColor,
  patchAccount
}

export default AccountsService
