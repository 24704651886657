import request from '../../shared/lib/request'
const rootApiUrl = process.env.REACT_APP_API_URL

function get() {
  return request({
    url: `${rootApiUrl}/notification/`,
    method: 'GET'
  })
}

function getNew() {
  return request({
    url: `${rootApiUrl}/notification/unread`,
    method: 'GET'
  })
}

function setRead(id) {
  return request({
    url: `${rootApiUrl}/notification/read/${id}`,
    method: 'POST',
    data: ''
  })
}

function setAllRead() {
  return request({
    url: `${rootApiUrl}/notification/markall/`,
    method: 'POST',
    data: ''
  })
}

function setViewed() {
  return request({
    url: `${rootApiUrl}/notification/viewall/`,
    method: 'POST',
    data: ''
  })
}

function setViewedByType(type) {
  return request({
    url: `${rootApiUrl}/notification/viewall/${type}`,
    method: 'POST',
    data: ''
  })
}

const NotificationService = {
  get,
  getNew,
  setRead,
  setAllRead,
  setViewed,
  setViewedByType
}

export default NotificationService
