// ** React Imports

import 'moment/min/locales'

import { createContext, useEffect, useState } from 'react'

import { IntlProvider } from 'react-intl'
import messagesDe from '@assets/data/locales/de.json'
import messagesEn from '@assets/data/locales/en.json'
import messagesFr from '@assets/data/locales/fr.json'
import moment from 'moment'
import { useAuth0 } from '../../authServices/auth0/auth0Service'
import { useTranslation } from 'react-i18next'
import userMessagesDe from '@src/assets/data/locales/de.json'
import userMessagesEn from '@src/assets/data/locales/en.json'
import userMessagesFr from '@src/assets/data/locales/fr.json'

// ** Intl Provider Import

// ** Core Language Data

// import messagesPt from '@assets/data/locales/pt.json'

// ** User Language Data

// import userMessagesPt from '@src/assets/data/locales/pt.json'

// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn, ...userMessagesEn },
  de: { ...messagesDe, ...userMessagesDe },
  fr: { ...messagesFr, ...userMessagesFr }
  // pt: { ...messagesPt, ...userMessagesPt }
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  // ** States
  const { user, logout } = useAuth0()
  const { t, i18n } = useTranslation()
  const [locale, setLocale] = useState('en')
  const [messages, setMessages] = useState(menuMessages['en'])

  useEffect(() => {
    if (user !== undefined) {
      i18n.changeLanguage(user['https://prm.iscom.io/user_metadata']?.lang)
      moment.locale(user['https://prm.iscom.io/user_metadata']?.lang)
      setLocale(user['https://prm.iscom.io/user_metadata']?.lang)
    }
  }, [user])
  // ** Switches Language
  const switchLanguage = (lang) => {
    i18n.changeLanguage(lang)
    moment.locale(lang)
    setLocale(lang)
    setMessages(menuMessages[lang])
  }

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
