import FileService from '../../../services/api/file'
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const SET_SOURCE = 'SET_SOURCE'
export const SET_GIFT = 'SET_GIFT'
export const SET_VIEWS = 'SET_VIEWS'
export const SET_COMMENTS = 'SET_COMMENTS'

export function uploadFile(username, body) {
  return (dispatch, _getState) => {
    FileService.post(username, body).then((response) => {
      dispatch({ type: UPLOAD_FILE, value: response.data })
    })
  }
}
