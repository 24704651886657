import request from '../../shared/lib/request'
const rootApiUrl = process.env.REACT_APP_API_URL

function get() {
  return request({
    url: `${rootApiUrl}/organization`
  })
}
function resetPassword(email) {
  return request({
    url: `${rootApiUrl}/organization/user/password/reset/${email}`,
    method: 'POST'
  })
}
function getById(id) {
  return request({
    url: `${rootApiUrl}/organization/user/${id}`
  })
}

function createUser(body) {
  return request({
    url: `${rootApiUrl}/organization/user`,
    method: 'POST',
    data: body
  })
}

function editUser(body) {
  return request({
    url: `${rootApiUrl}/organization/user/edit/${body.id}`,
    method: 'POST',
    data: body
  })
}

function del(id) {
  return request({
    url: `${rootApiUrl}/organization/delete/${id}`,
    method: 'DELETE'
  })
}

function suspend(id) {
  return request({
    url: `${rootApiUrl}/organization/suspend/${id}`,
    method: 'POST'
  })
}

function lift(id) {
  return request({
    url: `${rootApiUrl}/organization/lift/${id}`,
    method: 'POST'
  })
}
function getUrl() {
  return `${rootApiUrl}/admin`
}

const OrgaService = {
  get,
  createUser,
  getById,
  del,
  suspend,
  lift,
  getUrl,
  editUser,
  resetPassword
}

export default OrgaService
