/* eslint-disable no-case-declarations */
const initialState = {
  loadingMain: true,
  loadingThread: false,
  chats: [],
  contacts: [],
  searchValue: '',
  searching: false,
  userProfile: {},
  selectedUser: {},
  filteredContacts: [],
  onlyMyAccounts: true
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_SEARCH': {
      return { ...state, contacts: [], searching: false }
    }
    case 'SEARCHING': {
      return { ...state, searchValue: action, searching: true }
    }
    case 'SEARCH_RESULTS': {
      return { ...state, contacts: action.data, searching: false }
    }
    case 'START_MAIN_LOAD':
      return {
        ...state,
        loadingMain: true
      }
    case 'END_MAIN_LOAD':
      return {
        ...state,
        loadingMain: false
      }
    case 'START_THREAD_LOAD':
      return {
        ...state,
        loadingThread: true
      }
    case 'END_THREAD_LOAD':
      return {
        ...state,
        loadingThread: false
      }
    case 'GET_USER_PROFILE':
      return { ...state, userProfile: action.userProfile }
    case 'GET_CHAT_CONTACTS':
      return {
        ...state,
        chats: action.data.chatContacts,
        contacts: []
      }
    case 'SELECT_CHAT':
      return { ...state, selectedUser: action.data }
    case 'SELECT_NEW_CONV': {
      const sel = { contact: action.data }
      return { ...state, selectedUser: sel }
    }
    case 'SEND_MSG':
      // ** Add new msg to chat
      const newMsg = action.data

      const msgs = state.selectedUser.msg ?? []
      const latestMessage = newMsg.message
      msgs.push(latestMessage)
      return {
        ...state,
        selectedUser: { ...state.selectedUser, chat: newMsg, msg: msgs }
      }
    case 'UPDATE_THREAD_ID': {
      return {
        ...state,
        selectedUser: { ...state.selectedUser },
        threadId: action.data.threadId
      }
    }
    case 'SWITCH_ACCOUNTS': {
      return { ...state, onlyMyAccounts: !state.onlyMyAccounts }
    }
    default:
      return state
  }
}

export default chatReducer
