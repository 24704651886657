import {
  CONNECTION_ESTABLISHED,
  INBOUND_NOTIFICATION,
  SET_NOTIFICATION
} from '../../actions/notification/index'

const initialState = {
  connectionId: '',
  lastMessage: {},
  notifications: []
}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case INBOUND_NOTIFICATION:
      return { ...state, lastMessage: action.value }
    case CONNECTION_ESTABLISHED:
      return { ...state, connectionId: action.value }
    case SET_NOTIFICATION: {
      return { ...state, notifications: action.value }
    }
    default:
      return state
  }
}

export default notificationReducer
