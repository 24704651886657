import request from '../../shared/lib/request'
const rootApiUrl = process.env.REACT_APP_API_URL

function post(id, body) {
  return request({
    url: `${rootApiUrl}/schedule/${id}`,
    method: 'POST',
    data: body,
  })
}

function getByInfluencer(influencer) {
  return request({
    url: `${rootApiUrl}/schedule/influencer/${influencer}`,
    method: 'GET',
  })
}

function getByAuthor(author) {
  return request({
    url: `${rootApiUrl}/schedule/author/${author}`,
    method: 'GET',
  })
}

function getByMonthRange(body) {
  return request({
    url: `${rootApiUrl}/schedule/range`,
    method: 'POST',
    data: body,
  })
}

function get() {
  return request({
    url: `${rootApiUrl}/schedule/`,
    method: 'GET',
  })
}

const ScheduleService = {
  get,
  post,
  getByInfluencer,
  getByAuthor,
  getByMonthRange,
}

export default ScheduleService
