import request from '../../shared/lib/request'
const rootApiUrl = process.env.REACT_APP_API_URL

function brands(client) {
  return request({
    url: `${rootApiUrl}/settings/competition/${client}/brands`,
    method: 'GET'
  })
}

function discoveryBrands() {
  return request({
    url: `${rootApiUrl}/settings/discovery/brands`,
    method: 'GET'
  })
}

function removeBrand(name) {
  return request({
    url: `${rootApiUrl}/settings/discovery/remove/${name}`,
    method: 'DELETE'
  })
}

function addBrand(name) {
  return request({
    url: `${rootApiUrl}/settings/discovery/add/${name}`,
    method: 'POST'
  })
}

function getXhsConfig() {
  return request({
    url: `${rootApiUrl}/settings/xhs`,
    method: 'GET'
  })
}

function getXhsBalance() {
  return request({
    url: `${rootApiUrl}/settings/xhs/balance`,
    method: 'GET'
  })
}

function addXhsKw(name) {
  return request({
    url: `${rootApiUrl}/settings/xhs/add/${name}`,
    method: 'POST'
  })
}

function removeXhsKw(name) {
  return request({
    url: `${rootApiUrl}/settings/xhs/remove/${name}`,
    method: 'DELETE'
  })
}

function toggleXhsActive() {
  return request({
    url: `${rootApiUrl}/settings/xhs/toggleActive`,
    method: 'GET'
  })
}

function saveXhsConfig(data) {
  return request({
    url: `${rootApiUrl}/settings/xhs`,
    method: 'POST',
    data
  })
}

const SettingsService = {
  brands,
  removeBrand,
  addBrand,
  discoveryBrands,
  getXhsConfig,
  addXhsKw,
  removeXhsKw,
  getXhsBalance,
  toggleXhsActive,
  saveXhsConfig
}

export default SettingsService
