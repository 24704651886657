import AccountsService from '../../../../../../../services/api/accounts'
import { Action } from 'history'
import CountriesService from '../../../../../../../services/api/countries'
import GiftsService from '../../../../../../../services/api/gifts'
import moment from 'moment'

export const SET_FETCH_START = 'manageGifts/SET_FETCH_START'
export const SET_FETCH_DONE = 'manageGifts/SET_FETCH_DONE'
export const SET_AREAS = 'manageGifts/SET_AREAS'
export const SET_FETCH_ACCS_START = 'manageGifts/SET_FETCH_ACCS_START'
export const SET_FETCH_ACCS_DONE = 'manageGifts/SET_FETCH_ACCS_DONE'
export const SET_SOURCE = 'manageGifts/SET_SOURCE'
export const SET_DATASET = 'manageGifts/SET_DATASET'
export const SET_ACCOUNTS = 'manageGifts/SET_ACCOUNTS'
export const SET_FILTER_ISTODAY = 'manageGifts/SET_FILTER_ISTODAY'
export const SET_OWNER = 'manageGifts/SET_OWNER'
export const SET_SP_DATE_START = 'manageGifts/SET_SP_DATE_START'
export const SET_DATE_START = 'manageGifts/SET_DATE_START'
export const SET_DATE_END = 'manageGifts/SET_DATE_END'
export const PUSH_TO_FILTERS = 'manageGifts/PUSH_TO_FILTERS'
export const REM_FROM_FILTERS = 'manageGifts/REM_FROM_FILTERS'
export const REM_FROM_BODY = 'manageGifts/REM_FROM_BODY'
export const POP_MODAL = 'manageGifts/POP_MODAL'
export const MODAL_CONTENT = 'manageGifts/MODAL_CONTENT'
export const SET_AREA = 'manageGifts/SET_AREA'
export const SET_TOGGLE_ERP = 'managedGifts/SET_SHOW_ERP'
export const SET_TOGGLE_OOS = 'managedGifts/SET_SHOW_OOS'
export const SET_TOGGLE_SHP = 'managedGifts/SET_TOGGLE_SHP'
export const RESET = 'managedGifts/RESET'
export const FILTER_USERNAME = 'manageGifts/FILTER_USERNAME'
export const FILTER_SKU = 'manageGifts/FILTER_SKU'
export const SELECT_ITEM = 'managedGifts/SELECT_ITEMS'
export const TOGGLE_IGNORE_FILTERS = 'managedGifts/TOGGLE_IGNORE_FILTERS'
export const SET_ORDER_STATUS = 'managedGifts/SET_ORDER_STATUS'
export const SET_LAST_READYON = 'managedGifts/SET_LAST_READYON'
export const FILTER_ID = 'FILTER_ID'
export const STOCK_MENU = [
  {
    label: 'All',
    value: 0
  },
  { label: 'In Stock', value: 1 },
  { label: 'Out Of Stock', value: 2 }
]

export const ERP_MENU = [
  {
    label: 'All',
    value: 0
  },
  { label: 'Complete', value: 1 },
  { label: 'Incomplete', value: 2 }
]
export const SHIP_MENU = [
  {
    label: 'All',
    value: 0
  },
  { label: 'Shipped', value: 1 },
  { label: 'Not Shipped', value: 2 }
]

let goTimer
const goTimerSet = (timer) => {
  goTimer = timer
}

export const urlFromState = () => (dispatch, _getState) => {
  const state = _getState().manageGifts

  let url = ''
  if (state.body.areas?.length) {
    url += `${url === '' ? '?' : '&'}areas=`
    const areas = state.body.areas.join(',')
    url += areas
  }

  if (state.body.ownerEmail?.length) {
    url += `${url === '' ? '?' : '&'}owners=`
    const owners = state.body.ownerEmail.join(',')
    url += owners
  }

  if (state.body.shippingStatus) {
    url += `${url === '' ? '?' : '&'}shippingStatus=${
      state.body.shippingStatus
    }`
  }

  if (state.body.stockStatus) {
    url += `${url === '' ? '?' : '&'}stockStatus=${state.body.stockStatus}`
  }

  if (state.body.erpStatus !== undefined) {
    url += `${url === '' ? '?' : '&'}erpStatus=${state.body.erpStatus}`
  }

  if (state.body.sender !== undefined) {
    url += `${url === '' ? '?' : '&'}sender=`
    const sender = state.body.sender
    url += sender
  }

  window.history.pushState({}, '', `${url}`)
}

const urgentFirst = (data) => {
  const urgents = data.filter((u) => u.urgent)
  const rest = data.filter((u) => !u.urgent)

  return [...urgents, ...rest]
}

const executeFetch = (toDispatch) => (dispatch, _getState) => {
  const state = _getState().manageGifts
  const body = state.body
  dispatch({ type: SET_DATASET, value: [] })
  dispatch({ type: SET_FETCH_START, value: undefined })
  GiftsService.search(body)
    .then((result) =>
      dispatch({ type: SET_DATASET, value: urgentFirst(result.data) })
    )
    .then(() => dispatch({ type: SET_FETCH_DONE }))
    .then(dispatch(toDispatch))
}

export const getInitial = (value) => (dispatch, _getState) => {
  dispatch({ type: SET_FETCH_START, value })
  // dispatch({ type: SET_OWNER, value })
  const state = _getState().manageGifts

  const body = state.body
  dispatch({ type: SET_DATASET, value: [] })
  CountriesService.getAreas().then((result) => {
    dispatch({ type: SET_AREAS, value: result.data })
  })
  GiftsService.search(body)
    .then((result) =>
      dispatch({ type: SET_DATASET, value: urgentFirst(result.data) })
    )
    // .then(
    //   dispatch({
    //     type: PUSH_TO_FILTERS,
    //     value: { key: 'ownerEmail', label: value }
    //   })
    // )
    .then(
      dispatch({
        type: PUSH_TO_FILTERS,
        value: { key: 'shippingAfter', label: 'This Month' }
      })
    )
    .then(() => dispatch({ type: SET_FETCH_DONE }))

  AccountsService.get().then((result) =>
    dispatch({ type: SET_ACCOUNTS, value: result.data })
  )
}

export const setSelectedItems = (value) => (dispatch) => {
  dispatch({ type: SELECT_ITEM, value })
}

export const toggleShipped = (value) => (dispatch, _getState) => {
  dispatch({ type: SET_TOGGLE_SHP, value })

  dispatch(
    executeFetch({
      type: PUSH_TO_FILTERS,
      value: { key: 'toggleShipped', label: value }
    })
  )
  dispatch(urlFromState())
}

export const toggleNeedErp = (value) => (dispatch, _getState) => {
  dispatch({ type: SET_TOGGLE_ERP, value })
  dispatch(
    executeFetch({
      type: PUSH_TO_FILTERS,
      value: { key: 'toggleNeedErp', label: value }
    })
  )
  dispatch(urlFromState())
}

export const toggleOutOfStock = (value) => (dispatch, _getState) => {
  dispatch({ type: SET_TOGGLE_OOS, value })
  dispatch(
    executeFetch({
      type: PUSH_TO_FILTERS,
      value: { key: 'toggleOutOfStock', label: value }
    })
  )
  dispatch(urlFromState())
}

export const setArea = (value) => (dispatch, _getState) => {
  dispatch({ type: SET_FETCH_START, value })
  dispatch({ type: SET_AREA, value })

  dispatch({ type: SET_DATASET, value: [] })
  dispatch(
    executeFetch({
      type: PUSH_TO_FILTERS,
      value: { key: 'areas', label: value }
    })
  )
  dispatch(urlFromState())
}

export const setOwner = (value) => (dispatch) => {
  dispatch({ type: SET_FETCH_START, value })
  dispatch({ type: SET_OWNER, value })

  dispatch(
    executeFetch({
      type: PUSH_TO_FILTERS,
      value: { key: 'ownerEmail', label: value }
    })
  )
  dispatch(urlFromState())
}

export const setSource = (value) => (dispatch) => {
  dispatch({ type: SET_FETCH_START, value })
  dispatch({ type: SET_SOURCE, value })

  dispatch({ type: SET_DATASET, value: [] })
  dispatch(
    executeFetch({
      type: PUSH_TO_FILTERS,
      value: { key: 'sender', label: value }
    })
  )

  dispatch(urlFromState())
}

export const removeFromFilters = (value) => (dispatch, _getState) => {
  dispatch({ type: REM_FROM_FILTERS, value })
  dispatch({ type: REM_FROM_BODY, value })
  dispatch({ type: SET_FETCH_START, value })
  const state = _getState().manageGifts
  dispatch({ type: SET_DATASET, value: [] })

  GiftsService.search(state.body)
    .then((result) => dispatch({ type: SET_DATASET, value: result.data }))
    .then(() => dispatch({ type: SET_FETCH_DONE }))
}

export const setTodayFilter = () => (dispatch, _getState) => {
  dispatch({ type: SET_FETCH_START })
  dispatch({ type: SET_DATASET, value: [] })
  dispatch({
    type: SET_FILTER_ISTODAY,
    value: moment(new Date(new Date().setHours(0, 0, 0, 0)))
  })
  const state = _getState().manageGifts
  GiftsService.search(state.body)
    .then((result) => dispatch({ type: SET_DATASET, value: result.data }))
    .then(() => dispatch({ type: SET_FETCH_DONE }))
    .then(
      dispatch({
        type: PUSH_TO_FILTERS,
        value: { key: 'shippingAfter', label: 'Today' }
      })
    )
}

export const setThisMonthFilter = (value) => (dispatch, _getState) => {
  const bdate = new Date()
  const startDate = new Date(bdate.getFullYear(), bdate.getMonth(), 1)
  const date = moment(startDate.toString())
  dispatch({ type: SET_FETCH_START, value })
  dispatch({ type: SET_DATASET, value: [] })
  dispatch({
    type: SET_DATE_START,
    value: date
  })

  const state = _getState().manageGifts
  GiftsService.search(state.body)
    .then((result) => dispatch({ type: SET_DATASET, value: result.data }))
    .then(() => dispatch({ type: SET_FETCH_DONE }))
    .then(
      dispatch({
        type: PUSH_TO_FILTERS,
        value: { key: 'shippingAfter', label: 'This Month' }
      })
    )
}

export const toggleIgnoreFilters = () => (dispatch, _getState) => {
  const state = _getState().manageGifts
  dispatch({ type: TOGGLE_IGNORE_FILTERS, value: !state.ignoreFilters })
}

export const searchUsername = (value) => (dispatch) => {
  dispatch({ type: FILTER_USERNAME, value })
  clearTimeout(goTimer)
  //console.log('Clear Timeout!')
  goTimerSet(
    setTimeout(() => {
      //console.log('Exec Timeout!')

      dispatch(
        executeFetch({
          type: PUSH_TO_FILTERS,
          value: { key: 'setUserame', label: value }
        })
      )
      dispatch(urlFromState())
    }, 300)
  )
}

export const searchId = (value) => (dispatch) => {
  dispatch({ type: FILTER_ID, value })
  clearTimeout(goTimer)

  goTimerSet(
    setTimeout(() => {
      dispatch(
        executeFetch({
          type: PUSH_TO_FILTERS,
          value: { key: 'setID', label: value }
        })
      )
      dispatch(urlFromState())
    }, 300)
  )
}

export const searchSKU = (value) => (dispatch) => {
  dispatch({ type: FILTER_SKU, value })
  clearTimeout(goTimer)

  goTimerSet(
    setTimeout(() => {
      dispatch(
        executeFetch({
          type: PUSH_TO_FILTERS,
          value: { key: 'setSKU', label: value }
        })
      )
      dispatch(urlFromState())
    }, 300)
  )
}

export const setShippedAfterFilter = (value) => (dispatch, _getState) => {
  const date = moment(value.toString())
  dispatch({ type: SET_FETCH_START, value })
  dispatch({ type: SET_DATASET, value: [] })
  dispatch({
    type: SET_SP_DATE_START,
    value: date
  })

  const state = _getState().manageGifts
  GiftsService.search(state.body)
    .then((result) => dispatch({ type: SET_DATASET, value: result.data }))
    .then(() => dispatch({ type: SET_FETCH_DONE }))
    .then(
      dispatch({
        type: PUSH_TO_FILTERS,
        value: { key: 'shippingAfter', label: `after: ${date.calendar()}` }
      })
    )
}

export const setAfterFilter = (value) => (dispatch) => {
  const date = value !== null ? moment(value.toString()) : null
  dispatch({ type: SET_FETCH_START, value })
  dispatch({ type: SET_DATASET, value: [] })
  dispatch({
    type: SET_DATE_START,
    value: date
  })

  dispatch(
    executeFetch({
      type: PUSH_TO_FILTERS,
      value: { key: 'shippingAfter', label: `after: ${date?.calendar()}` }
    })
  )
}

export const setBeforeFilter = (value) => (dispatch) => {
  const date = value !== null ? moment(value.toString()) : null
  dispatch({ type: SET_FETCH_START, value })
  dispatch({ type: SET_DATASET, value: [] })
  dispatch({
    type: SET_DATE_END,
    value: date
  })

  dispatch(
    executeFetch({
      type: PUSH_TO_FILTERS,
      value: { key: 'shippingBefore', label: `before: ${date?.calendar()}` }
    })
  )
}

export const closeModal = () => (dispatch, _getState) => {
  dispatch({ type: POP_MODAL, value: false })
  dispatch({ type: MODAL_CONTENT, value: '' })
}

export const openModal = (value) => (dispatch) => {
  dispatch({ type: MODAL_CONTENT, value })
  dispatch({ type: POP_MODAL, value: true })
}

export const toggleModal = () => (dispatch, _getState) => {
  const state = _getState().manageGifts
  dispatch({ type: POP_MODAL, value: !state.popModal })
}

export const updateOrderStatus = (id) => (dispatch, _getState) => {
  const state = _getState().manageGifts
  const data = Object.assign([], state.data)
  const next = []
  for (let index = 0; index < data.length; index++) {
    const order = data[index]
    if (order.id === id) {
      const newOrder = Object.assign({}, order)
      newOrder.outOfStock = false
      next.push(newOrder)
    } else {
      next.push(order)
    }
  }

  dispatch({ type: SET_ORDER_STATUS, value: next })
}

export const isReadyOnLast = (id) => (dispatch, _getState) => {
  const state = _getState().manageGifts
  return state.lastChangedId === id
}

export const updateSelectedItemsFromDateOf =
  (id) => async (dispatch, _getState) => {
    const state = _getState().manageGifts
    const data = Object.assign([], state.data)
    const referenceItem = data.find((d) => d.id === id)

    if (!referenceItem) throw 'Cannot find reference id'
    const selectedItems = state.selectedItems.map((c) => c.id)

    if (selectedItems?.length === 0) return
    const next = Object.assign([])
    for (let index = 0; index < data.length; index++) {
      const order = data[index]
      if (selectedItems.includes(order.id)) {
        const newOrder = Object.assign({}, order)
        const backup = newOrder.shippingDate
        newOrder.shippingDate = referenceItem.shippingDate
        await GiftsService.patchDate(newOrder.id, referenceItem.shippingDate)
          .then((result) => {
            next.push(newOrder)
          })
          .catch(() => {
            newOrder.shippingDate = backup
            next.push(newOrder)
          })
      } else {
        next.push(order)
      }
    }
    dispatch({ type: SET_ORDER_STATUS, value: [] })

    dispatch({ type: SET_ORDER_STATUS, value: next })
  }

export const updateOrderReadyOn = (id, date) => (dispatch, _getState) => {
  const state = _getState().manageGifts
  const data = Object.assign([], state.data)
  const next = Object.assign([])

  for (let index = 0; index < data.length; index++) {
    const order = data[index]
    if (order.id === id) {
      const newOrder = Object.assign({}, order)
      newOrder.shippingDate = date

      dispatch({ type: SET_LAST_READYON, value: id })
      next.push(newOrder)
    } else {
      next.push(order)
    }
  }
  dispatch({ type: SET_ORDER_STATUS, value: next })
}

export const toggleBoolPropertySelected =
  (property) => (dispatch, _getState) => {
    const state = _getState().manageGifts
    const data = Object.assign([], state.data)
    const selectedItems = state.selectedItems.map((c) => c.id)

    if (selectedItems?.length === 0) return
    const next = []
    for (let index = 0; index < data.length; index++) {
      const order = data[index]
      if (selectedItems.includes(order.id)) {
        const newOrder = Object.assign({}, order)
        newOrder[property] = !newOrder[property]
        next.push(newOrder)
      } else {
        next.push(order)
      }
    }

    GiftsService.toggle(selectedItems, property).then(() =>
      dispatch({ type: SET_ORDER_STATUS, value: next })
    )
  }

export const selectPage = () => (dispatch, _getState) => {
  const state = _getState().manageGifts
}
