import React from 'react'
import UncontrolledCarouselMix from '../../../extensions/UncontrolledCarouselMix'

class CarouselUncontrolled extends React.Component {
  state = {
    activeTab: '1'
  }

  render() {
    return (
      <React.Fragment>
        <UncontrolledCarouselMix
          popinLayout={this.props.popinLayout}
          discoveryLayout={this.props.discoveryLayout}
          items={this.props.data}
          autoPlay={false}
        />
      </React.Fragment>
    )
  }
}

export default CarouselUncontrolled
