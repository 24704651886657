import request from '../../shared/lib/request'
const rootApiUrl = process.env.REACT_APP_API_URL

function post(username, body, type) {
  const mimetype = type.split('/')[0]
  const extension = type.split('/')[1]

  return request({
    url: `${rootApiUrl}/file/${username}/${mimetype}/${extension}`,
    method: 'POST',
    data: body,
    headers: {
      'Content-Type': type
    }
  })
}

function uploadFiles(formData) {
  return request({
    url: `${rootApiUrl}/file/UploadFiles`, // Adjust this URL to match your actual endpoint
    method: 'POST',
    data: formData,
    headers: {
      // 'Content-Type': 'multipart/form-data' is not explicitly set
      // allowing the browser to set this header with the proper boundary
    }
  })
}

function getFilesFor(internalId) {
  return request({
    url: `${rootApiUrl}/file/${internalId}`, // Adjust this URL to match your actual endpoint
    method: 'GET'
  })
}

function deleteFile(id, type) {
  return request({
    url: `${rootApiUrl}/file/${id}/${type}`, // Adjust this URL to match your actual endpoint
    method: 'DELETE'
  })
}

const FileService = {
  getFilesFor,
  deleteFile,
  uploadFiles,
  post //, update, delete, etc. ...
}

export default FileService
