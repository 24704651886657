/* eslint-disable array-bracket-newline */
import '../../../../../node_modules/yet-another-react-lightbox/dist/styles.css'

import {
  Button,
  Card,
  CardImg,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'
import React, { useState } from 'react'
import { fetchData, fetchMore, toggleModal } from './store/actions'
import { useDispatch, useSelector } from 'react-redux'

import Lightbox from 'yet-another-react-lightbox'
import MediaTypeView from '../../../extensions/MediaTypeView'
import Video from '../../../../../node_modules/yet-another-react-lightbox/dist/plugins/video'

const PostModal = (props) => {
  const dispatch = useDispatch()
  let nItem
  const store = useSelector((state) => state.crawlReducer)
  // if (store.document !== null && store.document.type.includes('post')) {
  //   nItem = Object.assign({}, store.document)
  //   nItem.popinLayout = true
  // }
  const mediaArray =
    store?.document?.mediaType === 1
      ? [{ src: store.document.images[0].localUri }]
      : store?.document?.mediaType === 2
      ? [
          {
            type: 'video',
            sources: [
              { src: store.document.videos[0].localUri, type: 'video/mp4' }
            ]
          }
        ]
      : store?.document?.mediaType === 8
      ? store.document.carousel
          .map((c) =>
            c.images
              .filter((i) => i.localUri)
              .map((i) => {
                const fileExtension = i.localUri?.split('.').pop() // Get the file extension to determine if it's an image or a video
                if (fileExtension === 'mp4') {
                  // If it's a video
                  return {
                    type: 'video',
                    sources: [{ src: i.localUri, type: 'video/mp4' }]
                  }
                } else {
                  // If it's an image
                  return {
                    src: i.localUri
                  }
                }
              })
          )
          .flat()
      : []

  return (
    // <div className="">
    //   <div className="basic-modal">
    //     <Modal
    //       className={
    //         store?.document?.type?.includes('post') ? 'modal-lg' : 'modal-md'
    //       }
    //       isOpen={store.modalState}
    //       toggle={() => {
    //         console.log(nItem)
    //         dispatch(toggleModal())
    //         nItem = null
    //         console.log(nItem)
    //       }}
    //     >
    //       <ModalHeader
    //         toggle={() => {
    //           dispatch(toggleModal())
    //           nItem = null
    //         }}
    //       >
    //         @{store?.document?.username}
    //       </ModalHeader>
    //       <ModalBody>
    //         {store.document !== null &&
    //         store?.document?.type?.includes('post') ? (
    //           <Card
    //             style={{
    //               width: 'fit-content',
    //               margin: 'auto'
    //             }}
    //           >
    //             <MediaTypeView data={nItem} />
    //           </Card>
    //         ) : // <MediaBlock
    //         //   lg={12}
    //         //   data={store.document}
    //         //   hideAdd={true}
    //         //   hideTag={true}
    //         // />
    //         store.document !== null && store.document.type.includes('story') ? (
    //           //   <MediaPostCard data={store.document} />
    //           <Card
    //             style={{
    //               width: 'fit-content',
    //               margin: 'auto'
    //             }}
    //           >
    //             {store.document.mediaType === 1 ? (
    //               <CardImg
    //                 top
    //                 className="img-fluid"
    //                 src={store.document.images[0].localUri}
    //                 style={{
    //                   maxHeight: '85vh'
    //                 }}
    //                 alt="card image cap"
    //               />
    //             ) : (
    //               <video
    //                 src={store.document.videos[0].localUri}
    //                 controls
    //                 muted
    //                 autoPlay="autoplay"
    //                 style={{ maxHeight: '85vh' }}
    //                 loop
    //               ></video>
    //             )}
    //           </Card>
    //         ) : (
    //           ''
    //         )}
    //       </ModalBody>
    //       {/* <ModalFooter>
    //         <Button color="danger" onClick={() => dispatch(toggleModal())}>
    //           Close
    //         </Button>
    //       </ModalFooter> */}
    //     </Modal>
    //   </div>
    // </div>

    <>
      {/* <FsLightbox
        onSlideChange={(instance) => console.log(instance)}
        toggler={store.modalState}
        sources={mediaArray}
      /> */}
      <Lightbox
        open={store.modalState}
        plugins={[Video]}
        close={() => dispatch(toggleModal())}
        slides={mediaArray}
        render={{
          buttonPrev: mediaArray.length <= 1 ? () => null : undefined,
          buttonNext: mediaArray.length <= 1 ? () => null : undefined
        }}
      />
    </>
  )
}

export default PostModal
