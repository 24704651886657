import request from '../../shared/lib/request'
const rootApiUrl = process.env.REACT_APP_API_URL

function post(body) {
  return request({
    url: `${rootApiUrl}/competition/search`,
    method: 'POST',
    data: body
  })
}

function page(acc, brand, stamp) {
  return request({
    url: `${rootApiUrl}/competition/${acc}/brand/${brand}/page/${stamp}`,
    method: 'GET'
  })
}

function posts(token) {
  return request({
    url: `${rootApiUrl}/competition/`,
    method: 'POST',
    data: token
  })
}

function blacklist(id) {
  return request({
    url: `${rootApiUrl}/competition/blacklist/${id}`,
    method: 'POST',
    data: ''
  })
}

function getBlacklisted() {
  return request({
    url: `${rootApiUrl}/competition/blacklists`,
    method: 'GET'
  })
}

function getBlacklistedPaginated(continuationToken) {
  return request({
    url: `${rootApiUrl}/competition/blacklists`,
    method: 'POST',
    data: { continuationToken }
  })
}

function remove(id) {
  return request({
    url: `${rootApiUrl}/competition/remove/${id}`,
    method: 'POST',
    data: ''
  })
}

function track(username) {
  return request({
    url: `${rootApiUrl}/competition/addUser/${username}`,
    method: 'GET'
  })
}

function trackXhs(username) {
  return request({
    url: `${rootApiUrl}/xhs/addUser/${username}`,
    method: 'GET'
  })
}

function getXhs() {
  return request({
    url: `${rootApiUrl}/xhs/discovery`,
    method: 'GET'
  })
}

function getXhsNext(token) {
  return request({
    url: `${rootApiUrl}/xhs/discovery`,
    method: 'POST',
    data: { continuationToken: token }
  })
}

const CompetitionService = {
  posts,
  getBlacklisted,
  getBlacklistedPaginated,
  post,
  page,
  blacklist,
  remove,
  track,
  trackXhs,
  getXhs,
  getXhsNext
}

export default CompetitionService
