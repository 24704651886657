import {
  CHAT_RECEIVE_SIGNAL,
  CHAT_RECEIVE_TYPING
} from '../../actions/chat/index'

const initialState = {
  chats: [],
  contacts: [],
  chatContacts: [],
  pinned: [],
  status: 'active',
  filteredContacts: [],
  filteredChats: [],
  globalLoading: true,
  chatLoading: false,
  menuBadges: '',
  someoneIsTyping: false,
  typers: []
}

const addThreadToChat = (chats, msgs) => {
  const threadId = msgs.threadId
  const chat = Object.values(chats).filter((c) => c.threadId === threadId)
  if (chat.length === 0) {
    return msgs
  }
  if (
    msgs.msg.length === 1 &&
    msgs.msg[0].textContent !== chat[0].msg[0].textContent
  ) {
    let checkAlreadyExists = false
    for (let index = 0; index < chat[0].msg.length; index++) {
      const element = chat[0].msg[index]
      if (element.textContent === msgs.msg[0].textContent) {
        checkAlreadyExists = true
      }
    }
    if (!checkAlreadyExists) {
      chat[0].msg.push(msgs.msg[0])
    }
  } else {
    chat[0].msg = msgs.msg
  }

  return chats
}

const chat = (state = initialState, action) => {
  switch (action.type) {
    case 'START_CHAT_LOAD': {
      return { ...state, chatLoading: true }
    }
    case 'END_CHAT_LOAD': {
      return { ...state, chatLoading: false }
    }
    case 'START_GLOBAL_LOAD': {
      console.log('start global load')
      return { ...state, globalLoading: true }
    }
    case 'END_GLOBAL_LOAD': {
      console.log('ended global load')
      return { ...state, globalLoading: false }
    }
    case 'GET_THREAD':
      return { ...state, chats: addThreadToChat(state.chats, action.msgs) }
    case CHAT_RECEIVE_SIGNAL:
      return {
        ...state,
        chats: addThreadToChat(state.chats, action.msgs),
        menuBadges: action.msgs?.menuBadges
      }
    case CHAT_RECEIVE_TYPING:
      {
        const localTypers = state.typers
        if (action.message.event === 'USER_TYPING') {
          const typer = parseInt(action.message.userPk, 10)
          if (localTypers.includes(typer)) return { ...state }
          else {
            localTypers.push(typer)
            return { ...state, someoneIsTyping: true, typers: localTypers }
          }
        }
        if (action.message.event === 'USER_PAUSE_TYPING') {
          let secondArray = []
          secondArray = localTypers.map((i) => {
            if (i !== parseInt(action.message.userPk, 10)) return i
          })
          return { ...state, someoneIsTyping: true, typers: secondArray }
        }
      }
      break
    case 'GET_CONTACTS':
      return { ...state, chats: action.chats, contacts: action.contacts }
    case 'GET_CHAT_CONTACTS':
      return { ...state, chatContacts: action.chats }
    case 'SEND_MESSAGE': {
      console.log(state)
      console.log(action)
      let chat = state.chats[action.id]
      if (chat) chat.msg.push(action.msg)
      else {
        chat = {
          uid: action.id,
          msg: []
        }
      }
      console.log(chat)

      let sendMsg, newChatContacts

      const oldChatContactsId = state.chatContacts.map((j) => j.uid)
      if (state.chats[action.id]) {
        const newState = state.chats
        const newChat = state.chats[action.id]

        Object.assign(newState[action.id], newChat)

        sendMsg = Object.assign({}, newState)
        //sendMsg = [...oldState, action.msg]
      } else {
        sendMsg = {
          ...state.chats,
          [action.id]: {
            isPinned: action.isPinned ?? false,
            msg: [action.msg],
            uid: action.id,
            threadId: action.threadId
          }
        }
      }
      if (!oldChatContactsId.includes(action.id)) {
        let extractContactInfo = state.contacts.find((k) => k.uid === action.id)
        if (!extractContactInfo) {
          extractContactInfo = state.filteredContacts.find(
            (k) => k.uid === action.id
          )
          if (extractContactInfo) {
            extractContactInfo.threadId = action.threadId
          }
        }
        newChatContacts = state.chatContacts.concat(extractContactInfo)
      } else {
        newChatContacts = state.chatContacts
      }
      console.log({ ...state, chatContacts: newChatContacts })
      return { ...state, chats: sendMsg, chatContacts: newChatContacts }
    }
    case 'CHANGE_STATUS':
      return { ...state, status: action.status }
    case 'MARK_AS_SEEN':
      // const marked = state.chats[action.id]
      // marked !== undefined &&
      //   marked.msg.forEach((msg) => {
      //     msg.isSeen = true
      //   })
      return { ...state }
    case 'SEARCH_CONTACTS':
      if (action.query.length) {
        const filteredContacts = state.contacts.filter((contact) => {
          return contact.username
            .toLowerCase()
            .includes(action.query.toLowerCase())
        })
        const filteredChats = state.chatContacts.filter((chat) => {
          return chat.username
            .toLowerCase()
            .includes(action.query.toLowerCase())
        })

        return { ...state, filteredContacts, filteredChats }
      } else {
        return { ...state }
      }
    case 'SEARCH_CONTACTS_REMOTE':
      if (action.query.length >= 3 && action.contacts.length) {
        let filteredContacts = state.contacts.filter((contact) => {
          return contact.username
            .toLowerCase()
            .includes(action.query.toLowerCase())
        })

        if (!filteredContacts.length) {
          //filteredContacts = state.contacts
          filteredContacts = filteredContacts.concat(action.contacts)
        }

        return { ...state, filteredContacts }
      } else {
        return { ...state }
      }
    case 'SET_PINNED': {
      const pinned = state.chats[action.id]
      if (pinned) {
        pinned.isPinned = action.value
        state.chatContacts.sort((a, b) => b.uid - a.uid)
        return { ...state }
      } else {
        return { ...state }
      }
    }

    default:
      return { ...state }
  }
}

export default chat
