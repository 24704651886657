import request from '../../shared/lib/request'
const rootApiUrl = process.env.REACT_APP_API_URL

function countMyUsers() {
  return request({
    url: `${rootApiUrl}/dashboard/users/mine`,
    method: 'GET'
  })
}

function countAllUsers() {
  return request({
    url: `${rootApiUrl}/dashboard/users/all`,
    method: 'GET'
  })
}
function countMineByTypeSource(type, source) {
  return request({
    url: `${rootApiUrl}/dashboard/medias/mine/${type}/${source}`,
    method: 'GET'
  })
}
function countByTypeSource(type, source) {
  return request({
    url: `${rootApiUrl}/dashboard/medias/${type}/${source}`,
    method: 'GET'
  })
}
function countAllReach() {
  return request({
    url: `${rootApiUrl}/dashboard/users/reach`,
    method: 'GET'
  })
}
function countAllPosts() {
  return request({
    url: `${rootApiUrl}/dashboard/posts/all`,
    method: 'GET'
  })
}
function countAllStories() {
  return request({
    url: `${rootApiUrl}/dashboard/stories/all`,
    method: 'GET'
  })
}
function getActivity(id) {
  return request({
    url: `${rootApiUrl}/dashboard/activity/${id}`,
    method: 'GET'
  })
}

function latestUsers(username) {
  return request({
    url: `${rootApiUrl}/dashboard/latestUsers/${username}`,
    method: 'GET'
  })
}
function aggArea() {
  return request({
    url: `${rootApiUrl}/dashboard/aggregate/area/count/`,
    method: 'GET'
  })
}

function giftsConfirmed() {
  return request({
    url: `${rootApiUrl}/dashboard/gifts/all/confirmed/`,
    method: 'GET'
  })
}

function giftsShipped() {
  return request({
    url: `${rootApiUrl}/dashboard/gifts/all/shipped/`,
    method: 'GET'
  })
}
function contacted() {
  return request({
    url: `${rootApiUrl}/dashboard/contacted`,
    method: 'GET'
  })
}

function aggFollowers() {
  return request({
    url: `${rootApiUrl}/dashboard/aggregate/followers/sum/`,
    method: 'GET'
  })
}

function giftsTargets() {
  return request({
    url: `${rootApiUrl}/dashboard/gifts/targets`,
    method: 'GET'
  })
}

function getGrowing() {
  return request({
    url: `${rootApiUrl}/dashboard/users/getGrowing`,
    method: 'GET'
  })
}

const DashService = {
  countMyUsers,
  countAllUsers,
  countAllReach,
  countAllPosts,
  countAllStories,
  getActivity,
  latestUsers,
  aggArea,
  aggFollowers,
  countByTypeSource,
  countMineByTypeSource,
  giftsConfirmed,
  giftsShipped,
  contacted,
  giftsTargets,
  getGrowing
  //   countGiftsToday,
  //   countGiftsCompare,
  //   countTotalGifts,
}

export default DashService
