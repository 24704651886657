// ** Initial State
const initialState = {
  events: [],
  range: {},
  selectedEvent: {},
  selectedCalendars: ['laurent@ocovi.com'],
  calendars: []
}
const eventsMapper = (events, calendars) => {
  return events.map((ev) => {
    const calendar = calendars.find((c) => c.id === ev.author)
    return {
      id: ev.id,
      title: ev.username,
      start: ev.date,
      end: ev.endRange,
      allDay: false,
      url: '',
      editable: false,
      color: calendar?.color,
      extendedProps: {
        calendar: ev.author,
        guests: ev.participants.map((p) => {
          return { value: p, label: p }
        }),
        location: ev.location,
        description: ev.comment
      }
    }
  })
}
const calenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_CALENDARS':
      return { ...state, calendars: action.value }
    case 'FETCH_EVENTS':
      return { ...state, events: eventsMapper(action.events, state.calendars) }
    case 'MERGE_EVENTS':
      return { ...state, events: eventsMapper(action.events, state.calendars) }
    case 'ADD_EVENT':
      return { ...state }
    case 'REMOVE_EVENT':
      return { ...state }
    case 'UPDATE_EVENT':
      return { ...state }
    case 'UPDATE_FILTERS': {
      // ** Updates Filters based on action filter
      const filterIndex = state.selectedCalendars.findIndex(
        (i) => i === action.filter
      )
      if (state.selectedCalendars.includes(action.filter)) {
        state.selectedCalendars.splice(filterIndex, 1)
      } else {
        state.selectedCalendars.push(action.filter)
      }
      if (state.selectedCalendars.length === 0) {
        state.events.length = 0
      }
      return { ...state }
    }
    case 'UPDATE_ALL_FILTERS': {
      // ** Updates All Filters based on action value
      const value = action.value
      let selected = []
      if (value === true) {
        selected = state.calendars.map((c) => c.id)
      } else {
        selected = []
      }
      return { ...state, selectedCalendars: selected }
    }
    case 'SELECT_EVENT':
      return { ...state, selectedEvent: action.event }
    case 'SET_RANGE': {
      return { ...state, range: action.value }
    }
    default:
      return state
  }
}

export default calenderReducer
